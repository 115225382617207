import { Stack, Chip } from "@mui/material";
import {
  PlanningActivityType,
  ResourcesByActivityTypeIdAndEmployeeIdRow,
} from "@cbex/data-access";
import { CustomTextField } from "@cbex/ui/input";
import { CustomTypographyComponent } from "@cbex/ui/text";
import { useTranslations } from "next-intl";
import React from "react";
import { CustomAutoCompleteController } from "@cbex/form/autocomplete";

interface PlanningResourcesProps {
  resources: ResourcesByActivityTypeIdAndEmployeeIdRow[];
  name: string;
  controlDisabled?: boolean;
  defaultValue?: string[];
  label?: string;
  required?: boolean;
  width?: number | string;
  control: any;
  errors?: any;
  inputSelectedActivityTypes?: PlanningActivityType[];
}

export const ExamResourcesAutoComplete = ({
  resources,
  controlDisabled,
  defaultValue,
  control,
  name,
  required,
  width,
  errors,
  inputSelectedActivityTypes,
}: PlanningResourcesProps) => {
  const [value, setValue] = React.useState<[]>([]);

  React.useEffect(() => {
    if (
      defaultValue &&
      defaultValue.length > 0 &&
      resources &&
      resources.length > 0
    ) {
      const newList = [];

      for (let index = 0; index < defaultValue.length; index++) {
        const element =
          typeof defaultValue[index] === "number"
            ? defaultValue[index]
            : //@ts-ignore
              defaultValue[index].resourceID;
        const foundRes = resources.find(
          (item: any) => item.resourceID === element
        );
        if (foundRes) {
          newList.push({
            //@ts-ignore
            resourceID: foundRes.resourceID,
            //@ts-ignore
            name: foundRes.name,
          });
        }
      }
      //@ts-ignore
      setValue([...newList]);
    } else {
      setValue([]);
    }
  }, [defaultValue, resources]);

  const t = useTranslations("labels");
  const setInternalVal = (
    inputArray: ResourcesByActivityTypeIdAndEmployeeIdRow[]
  ) => {
    const localResources = inputArray.map((res) => ({
      resourceID: res.resourceID,
      name: res.name,
    }));
    //@ts-ignore
    setValue([...localResources]);
  };

  const activitySettings =
    inputSelectedActivityTypes &&
    inputSelectedActivityTypes.length > 0 &&
    inputSelectedActivityTypes[0].settings;

  return (
    <Stack id={"GetPlanningResourcesList"}>
      {resources &&
        resources.length > 0 &&
        activitySettings &&
        activitySettings.allowSelectResource && (
          <CustomAutoCompleteController
            name={name}
            control={control}
            id="asynchronous-planningResources"
            sx={{ width: width ? width : 500 }}
            //@ts-ignore
            onCustomChange={(val) => setInternalVal(val)}
            disabled={controlDisabled}
            isOptionEqualToValue={(option, val) =>
              option.resourceID === val.resourceID
            }
            getOptionLabel={(option) => option.name}
            renderOption={(propsVal: any, option: any) => (
              <li {...propsVal} key={option.resourceID}>
                <Stack width={"100%"}>
                  <CustomTypographyComponent>
                    {option.name}
                  </CustomTypographyComponent>
                </Stack>
              </li>
            )}
            // @ts-ignore
            options={resources}
            multiple={true}
            value={value || []}
            defaultValue={value || []}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option.resourceID}
                  label={option.name}
                />
              ))
            }
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="filled"
                size={"small"}
                required={required}
                helperText={
                  !!errors &&
                  //@ts-ignore
                  errors.message
                }
                error={!!errors}
                controldisabled={controlDisabled}
                label={t("examiner")}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        )}
    </Stack>
  );
};

export default ExamResourcesAutoComplete;
