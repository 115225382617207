import { Stack, CircularProgress } from "@mui/material";
import {
  GetActivityListDocument,
  ActivityTypeByIDsRow,
} from "@cbex/data-access";
import { CustomAutoComplete, CustomTextField } from "@cbex/ui/input";
import { CustomTypographyComponent } from "@cbex/ui/text";
import { useTranslations } from "next-intl";
import React from "react";
import { useQuery } from "@apollo/client";

interface GetActivityTypeListProps {
  controlDisabled: boolean;
  inputParam: string;
  onValueChanged: (val: any) => void;
  label?: string;
  required?: boolean;
  width?: number | string;
}

export const GetActivityTypeList = (props: GetActivityTypeListProps) => {
  const {
    controlDisabled,
    inputParam,
    onValueChanged,
    label,
    required,
    width,
  } = props;
  const t = useTranslations();
  const { data: activityTypeData, loading: activityTypeDataLoading } = useQuery(
    GetActivityListDocument,
    {}
  );

  const [activityType, setActivityType] = React.useState<any>(
    inputParam || {
      id: "",
      name: "",
    }
  );
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [activityTypeList, setActivityTypeList] = React.useState<
    ActivityTypeByIDsRow[]
  >([]);

  React.useEffect(() => {
    if (
      !activityTypeDataLoading &&
      activityTypeData &&
      activityTypeData.ActivityList &&
      activityTypeData.ActivityList.length > 0
    ) {
      const newActivityType = activityTypeData.ActivityList.map(
        (item: ActivityTypeByIDsRow) => ({
          id: item.activityTypeID,
          name: item.Name,
        })
      );
      //@ts-ignore
      setActivityTypeList([...newActivityType]);
    } else {
      setActivityTypeList([]);
    }
  }, [activityTypeData, activityTypeDataLoading]);

  React.useEffect(() => {
    if (inputParam && activityTypeList) {
      const item = activityTypeList.find(
        //@ts-ignore
        (activity: any) => activity.id === inputParam
      );

      if (item) {
        setActivityType({
          //@ts-ignore
          id: item.activityTypeID,
          //@ts-ignore
          name: item.name,
        });
      }
    }
  }, [inputParam, activityTypeList]);

  const handleActivityTypeChange = (value: any) => {
    setActivityType(value);
    if (onValueChanged && value) {
      onValueChanged(value);
    }
  };

  return (
    <Stack id={"activityType"}>
      {activityTypeList && activityTypeList.length > 0 && (
        <CustomAutoComplete
          id="asynchronous-activityType"
          sx={{ width: width ? width : 300 }}
          open={open}
          size="small"
          disabled={controlDisabled}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          // @ts-ignore
          onChange={(event: any, newValue: string | null) =>
            handleActivityTypeChange(newValue)
          }
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          // @ts-ignore
          isOptionEqualToValue={(option, val) => option.id === val.id}
          // @ts-ignore
          getOptionLabel={(option) =>
            // @ts-ignore
            option.name
          }
          // @ts-ignore
          renderOption={(propsVal: any, option: any) => (
            <li {...propsVal} key={option.id}>
              <Stack width={"100%"}>
                <CustomTypographyComponent>
                  {option.name}
                </CustomTypographyComponent>
              </Stack>
            </li>
          )}
          value={activityType}
          // @ts-ignore
          options={activityTypeList}
          loading={activityTypeDataLoading}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              variant="filled"
              size={"small"}
              required={required}
              controldisabled={controlDisabled}
              label={label ? label : t("activityType")}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {activityTypeDataLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    </Stack>
  );
};

export default GetActivityTypeList;
