import { Stack } from "@mui/material";
import {
  ResourceListByActivityTypeIdDocument,
  ResourcesByActivityTypeIdAndEmployeeIdRow,
} from "@cbex/data-access";
import { CustomAutoComplete, CustomTextField } from "@cbex/ui/input";
import { CustomTypographyComponent } from "@cbex/ui/text";
import { useTranslations } from "next-intl";
import React from "react";
import { useQuery } from "@apollo/client";

interface GetActivityTypeListProps {
  controlDisabled: boolean;
  inputParam: string;
  activityTypeID: string;
  onValueChanged: (val: string) => void;
  label?: string;
  required?: boolean;
  width?: number | string;
}

export const GetPlanningResourcesList = (props: GetActivityTypeListProps) => {
  const {
    controlDisabled,
    inputParam,
    onValueChanged,
    activityTypeID,
    label,
    required,
    width,
  } = props;
  const t = useTranslations();
  const { data: resourcesDataList, loading: resourcesDataListLoading } =
    useQuery(ResourceListByActivityTypeIdDocument, {
      variables: {
        activityTypeID: activityTypeID,
      },
    });
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [resource, setResource] = React.useState<any>(
    inputParam || {
      id: "",
      name: "",
    }
  );
  const [resourceList, setResourceList] = React.useState<
    ResourcesByActivityTypeIdAndEmployeeIdRow[]
  >([]);
  React.useEffect(() => {
    if (
      !resourcesDataListLoading &&
      resourcesDataList &&
      resourcesDataList.ResourceListByActivityTypeID &&
      resourcesDataList.ResourceListByActivityTypeID.length > 0
    ) {
      const newRosources = resourcesDataList.ResourceListByActivityTypeID.map(
        (resourceVal: ResourcesByActivityTypeIdAndEmployeeIdRow) => ({
          id: resourceVal.resourceID,
          name: resourceVal.name,
        })
      );
      //@ts-ignore
      setResourceList([...newRosources]);
    } else {
      setResourceList([]);
    }
  }, [resourcesDataList, resourcesDataListLoading]);

  React.useEffect(() => {
    if (inputParam) {
      const item = resourceList.find(
        (resourceInput: ResourcesByActivityTypeIdAndEmployeeIdRow) =>
          //@ts-ignore
          resourceInput.id === inputParam
      );
      if (item) {
        setResource({
          //@ts-ignore
          id: item.id,
          name: item.name,
        });
      }
    }
  }, [inputParam, resourceList]);

  const handelResourceSelected = (value: any) => {
    setResource(value);
    if (onValueChanged) {
      onValueChanged(value.id);
    }
  };

  return (
    <Stack id={"GetPlanningResourcesList"}>
      {resourceList && resourceList.length > 0 && (
        <CustomAutoComplete
          id="asynchronous-activityType"
          sx={{ width: width ? width : 300 }}
          open={open}
          size="small"
          disabled={controlDisabled}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          // @ts-ignore
          onChange={(event: any, newValue: any | null) =>
            handelResourceSelected(newValue)
          }
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          // @ts-ignore
          isOptionEqualToValue={(option, val) => option.id === val.id}
          // @ts-ignore
          getOptionLabel={(option) =>
            // @ts-ignore
            option.name
          }
          // @ts-ignore
          renderOption={(propsVal: any, option: any) => (
            <li {...propsVal} key={option.id}>
              <Stack width={"100%"}>
                <CustomTypographyComponent>
                  {option.name}
                </CustomTypographyComponent>
              </Stack>
            </li>
          )}
          value={resource}
          // @ts-ignore
          options={resourceList}
          loading={resourcesDataListLoading}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              variant="filled"
              size={"small"}
              required={required}
              controldisabled={controlDisabled}
              label={label ? t(label) : t("resources")}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      )}
    </Stack>
  );
};

export default GetPlanningResourcesList;
