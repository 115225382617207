import React from "react";
import { CustomTypographyComponent } from "@cbex/ui/text";
import { useTheme } from "@cbex/utils/theme";
import { Stack, Chip, Paper, IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";

import EventNoteIcon from "@mui/icons-material/EventNote";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import PeopleIcon from "@mui/icons-material/People";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  CbexPortalExamOverviewWithFilterOptionsRow,
  CbexPortalExamHistoryWithFilterOptionsRow,
  CertificateDocumentDataByMomentIdDocument,
  GetCbexPresenceDocumentDataDocument,
  ActivitiesByActivityIDsRow,
} from "@cbex/data-access";
import {
  downLoadCommunisPDF,
  downLoadCommunisZip,
  mapCbexStatusColours,
} from "../utils";
import { useTranslations } from "next-intl";
import { CandidateOverviewModal } from "../modals";
import { useLazyQuery } from "@apollo/client";

interface ActivityCardProps {
  inputData:
    | CbexPortalExamOverviewWithFilterOptionsRow
    | CbexPortalExamHistoryWithFilterOptionsRow;
  cardWidth?: number | string;
  isActivity?: boolean;
  isHistory?: boolean;
  isResultsCollected?: boolean;
  filterVar: any;
  onExamPressed?: (momentID: string) => void;
  refetch: any;
}

export const ActivityCard = (props: ActivityCardProps) => {
  const {
    inputData,
    cardWidth,
    isActivity = false,
    isHistory = false,
    isResultsCollected = false,
    filterVar,
    refetch,
    onExamPressed,
  } = props;
  //@ts-ignore
  const [activityCardData, setActivityCardData] = React.useState<
    | CbexPortalExamOverviewWithFilterOptionsRow
    | CbexPortalExamHistoryWithFilterOptionsRow
  >();
  const theme = useTheme();

  const [candidateModal, setCandidateModal] = React.useState<boolean>(false);
  const t = useTranslations();
  React.useEffect(() => {
    if (inputData) {
      setActivityCardData(inputData);
    }
  }, [inputData]);

  const handleEditModalOpenState = (momentID: string) => {
    onExamPressed(momentID);
  };

  const handleCandidateModalOpenState = () => {
    setCandidateModal(!candidateModal);
  };

  const [
    getCertificateDocumentData,
    { data: CertificateDocumentData, loading: CertificateDocumentDataLoading },
  ] = useLazyQuery(CertificateDocumentDataByMomentIdDocument, {
    variables: {
      momentID: "",
    },
    fetchPolicy: "network-only",
    //@ts-ignore
  });

  const handleDownloadClick = (momentID: string) => {
    getCertificateDocumentData({
      variables: {
        momentID: momentID,
      },
    }).then((val) => {
      const error = downLoadCommunisZip({
        pdfObject: val.data.CertificateDocumentDataByMomentID,
        templateType:
          val.data.CertificateDocumentDataByMomentID[0].activity.properties
            .template,
      });
    });
  };

  const [getCBEXPresenceDocument, { data: CBEXPresenceDocumentData }] =
    useLazyQuery(GetCbexPresenceDocumentDataDocument, {
      variables: {
        momentID: "",
      },
      fetchPolicy: "network-only",
    });

  const handleDownloadPresenceClick = (momentID: string) => {
    getCBEXPresenceDocument({
      variables: {
        momentID: momentID,
      },
    }).then((val) => {
      const error = downLoadCommunisPDF({
        pdfObject: val.data.CBEXExamDocumentData,
        templateType: "presencelist",
        downloadName: "presentielijst",
      });
    });
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: theme.appColors.surfaceContainer,
        border: "none",
      }}
    >
      {activityCardData && (
        <Stack
          spacing={2}
          padding={2}
          width={cardWidth || "100%"}
          id="ActivityCard"
          direction={"row"}
        >
          <Stack padding={2} width="40%">
            <Stack width="100%" spacing={2}>
              <Stack
                spacing={{ md: 4, sm: 1 }}
                direction={{ sm: "column", md: "row" }}
              >
                <Stack
                  minWidth={{ md: 400, xs: 100 }}
                  spacing={{ md: 3, sm: 1 }}
                  direction={{ sm: "column", md: "row" }}
                >
                  <CustomTypographyComponent variant="h6">
                    {dayjs(activityCardData.moment.startDate).format(
                      "DD-MM-YYYY HH:mm"
                    )}
                  </CustomTypographyComponent>
                  <CustomTypographyComponent variant="h6">
                    {activityCardData.moment.location.city}
                  </CustomTypographyComponent>
                </Stack>
              </Stack>
              <Stack direction={"row"}>
                <CustomTypographyComponent mr={4} variant="body1">
                  Locatie :
                </CustomTypographyComponent>
                <CustomTypographyComponent variant="body1">
                  {activityCardData.moment.location.name}{" "}
                  {activityCardData.moment.location.street}{" "}
                  {activityCardData.moment.location.number}
                </CustomTypographyComponent>
              </Stack>
              <Stack pt={3}>
                <Stack direction={"row"}>
                  <CustomTypographyComponent width={170} variant="body2">
                    Aantal kandidaten :
                  </CustomTypographyComponent>
                  <CustomTypographyComponent variant="body2">
                    {activityCardData.totalOrganizationCandidates}
                  </CustomTypographyComponent>
                </Stack>

                <Stack direction={"row"}>
                  <CustomTypographyComponent width={170} variant="body2">
                    Totaal aantal kandidaten :
                  </CustomTypographyComponent>
                  <CustomTypographyComponent variant="body2">
                    {activityCardData.totalCandidates}
                  </CustomTypographyComponent>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack padding={2} width={"40%"}>
            <Stack>
              {activityCardData &&
                activityCardData.activity &&
                activityCardData.activity.length > 0 &&
                activityCardData.activity.map(
                  (act: ActivitiesByActivityIDsRow) => (
                    <CustomTypographyComponent
                      key={act.activityID}
                      variant="subtitle1"
                    >
                      {act.name}
                    </CustomTypographyComponent>
                  )
                )}
            </Stack>
          </Stack>
          <Stack padding={2} width={"20%"} justifyContent={"space-between"}>
            <Stack
              justifyContent={"flex-end"}
              width="100%"
              spacing={2}
              direction="row"
            >
              {/* Not needed right now. enable if we have the data. 
                        <Chip
                           variant="outlined"
                           sx={{
                              backgroundColor: theme.appColors.contentBackground.subtle,
                           }}
                           label="Open inschrijving"
                        /> */}
              <Chip
                sx={{
                  backgroundColor: mapCbexStatusColours(
                    activityCardData.status,
                    theme
                  ),
                  color: theme.appColors.surfaceContainer,
                }}
                label={t(`value.${activityCardData.status}`)}
              />
            </Stack>
            <Stack alignItems={"flex-end"} width={"100%"}>
              <Stack spacing={2} direction="row" marginRight={"10px"}>
                {isActivity && activityCardData && !isResultsCollected && (
                  <IconButton
                    onClick={() =>
                      handleEditModalOpenState(activityCardData.momentID)
                    }
                    size="large"
                    sx={{
                      color: theme.palette.primary.main,
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                      padding: 0,
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title={t("common.viewExam")} placement="bottom">
                      <EventNoteIcon />
                    </Tooltip>
                  </IconButton>
                )}
                {(isHistory || isResultsCollected) && (
                  <IconButton
                    disableRipple
                    onClick={handleCandidateModalOpenState}
                    sx={{
                      color: theme.palette.primary.main,
                      padding: 0,
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip
                      title={t("labels.viewCandidate")}
                      placement="bottom"
                    >
                      <PeopleIcon />
                    </Tooltip>
                  </IconButton>
                )}
                <IconButton
                  disableRipple
                  onClick={() =>
                    handleDownloadPresenceClick(
                      activityCardData.moment.momentID
                    )
                  }
                  sx={{
                    color: theme.palette.primary.main,
                    padding: 0,
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    title={t("labels.downloadPresenceList")}
                    placement="bottom"
                  >
                    <CoPresentIcon />
                  </Tooltip>
                </IconButton>
                {!isActivity &&
                  activityCardData.passedCount > 0 &&
                  activityCardData.activity[0].properties.template !==
                    undefined && (
                    <IconButton
                      disableRipple
                      onClick={() =>
                        handleDownloadClick(activityCardData.momentID)
                      }
                      sx={{
                        color: theme.palette.primary.main,
                        padding: 0,
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip
                        title={t("labels.downloadExamDetails")}
                        placement="bottom"
                      >
                        <AttachFileIcon />
                      </Tooltip>
                    </IconButton>
                  )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}

      {candidateModal && (
        <CandidateOverviewModal
          city={activityCardData.moment.location.city}
          startDate={activityCardData.startDate}
          onModalOpen={candidateModal}
          momentID={activityCardData.moment.momentID}
          onModalClose={handleCandidateModalOpenState}
        />
      )}
    </Paper>
  );
};

export default ActivityCard;
