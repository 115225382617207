import { CbexMomentExam } from "@cbex/data-access";
import dayjs from "dayjs";

export const getExamEditState = (input: CbexMomentExam): boolean => {
  const settings = input.activities[0].activityType.settings;
  if (settings) {
    if (settings.allowedHoursToChange) {
      const today = dayjs();
      const hourDiff = dayjs(input.moment.startDate).diff(today, "hours");

      if (hourDiff >= settings.allowedHoursToChange) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return true;
  }
};
