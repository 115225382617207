import { PlanningActivityType } from "@cbex/data-access";

export const removeInArray = (
  a: PlanningActivityType[],
  b: PlanningActivityType[]
) => {
  const newArrr = a.filter(
    (ar) => !b.find((rm) => rm.activityTypeID === ar.activityTypeID)
  );
  return newArrr;
};

export const not = (a: readonly any[], b: readonly any[]) =>
  a.filter((value: any) => b.indexOf(value) === -1);

export const intersection = (a: readonly any[], b: readonly any[]) =>
  a.filter((value: any) =>
    b.find(
      (bt) => bt.activityTypeID.toString() === value.activityTypeID.toString()
    )
  );
