import React from "react";
import { Chip, Stack } from "@mui/material";
import { CustomAutoComplete, CustomTextField } from "@cbex/ui/input";
import {
  GetActivityTypeDocument,
  PlanningActivityType,
} from "@cbex/data-access";
import { useTranslations } from "next-intl";
import { useQuery } from "@apollo/client";

interface ActivityTypeAutoCompleteProps {
  defaultValue?: any;
  width?: number;
  onActivityChange?: (items: PlanningActivityType[]) => void;
}

export const ActivityTypeAutoCompleteComponent = ({
  defaultValue,
  width,
  onActivityChange,
}: ActivityTypeAutoCompleteProps) => {
  const t = useTranslations("labels");
  const [value, setValue] = React.useState([]);
  const [planningActivityTypes, setPlanningActivityTypes] = React.useState<
    PlanningActivityType[]
  >([]);
  const { data: activityTypeData, loading: activityTypeDataLoading } = useQuery(
    GetActivityTypeDocument,
    {
      variables: {},
      fetchPolicy: "network-only",
    }
  );

  React.useEffect(() => {
    if (
      !activityTypeDataLoading &&
      activityTypeData &&
      activityTypeData.ActivityTypes &&
      activityTypeData.ActivityTypes.length > 0
    ) {
      const newActivityType = activityTypeData.ActivityTypes.map(
        (item: PlanningActivityType) => ({
          activityTypeID: item.activityTypeID,
          name: item.name,
          competenceID: item.competenceID,
          settings: item.settings,
        })
      );
      setPlanningActivityTypes([...newActivityType]);
    } else {
      setPlanningActivityTypes([]);
    }
  }, [activityTypeData, activityTypeDataLoading]);

  React.useEffect(() => {
    if (
      defaultValue &&
      defaultValue.length > 0 &&
      planningActivityTypes &&
      planningActivityTypes.length > 0
    ) {
      const newList = [];
      for (let index = 0; index < defaultValue.length; index++) {
        const element =
          typeof defaultValue[index] === "number"
            ? defaultValue[index]
            : //@ts-ignore
              defaultValue[index].activityTypeID;

        const foundPlanningAct = planningActivityTypes.find(
          (item: PlanningActivityType) => item.activityTypeID === element
        );
        if (foundPlanningAct) {
          newList.push({
            activityTypeID: foundPlanningAct.activityTypeID,
            //@ts-ignore
            name: foundPlanningAct.name,
            //@ts-ignore
            competenceID: foundPlanningAct.competenceID,
            settings: foundPlanningAct.settings,
          });
        }
      }
      setValue(newList);
    }
  }, [defaultValue, planningActivityTypes]);

  const handleActivityChange = (inputValue: any) => {
    setValue(inputValue);
    if (onActivityChange) {
      onActivityChange(inputValue);
    }
  };

  return (
    <Stack height={48} id="ActivityTypeAutoComplete">
      <CustomAutoComplete
        id="async-activitiesAutoComplete"
        sx={{ width: width ? width : 500, height: 48 }}
        disablePortal
        //disableRemoveOption={isEditForm}
        disableClearable={true}
        disabled={false}
        size="small"
        onChange={(_, val) => handleActivityChange(val)}
        disableCloseOnSelect
        multiple={true}
        limitTags={2}
        //@ts-ignore
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, val) =>
          option.activityTypeID === val.activityTypeID
        }
        // @ts-ignore
        renderOption={(props, option) => (
          <li {...props} id={option.activityTypeID} key={option.activityTypeID}>
            {option.name}
          </li>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              sx={{ height: 20 }}
              key={option.activityTypeID}
              label={option.name}
            />
          ))
        }
        value={value || []}
        options={planningActivityTypes}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            controldisabled={false}
            variant="filled"
            label={t("activityType")}
          />
        )}
      />
    </Stack>
  );
};

export default ActivityTypeAutoCompleteComponent;
