import React from "react";
import { useTranslations } from "next-intl";
import {
  Stack,
  FormControl,
  Paper,
  Button,
  Grid,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { CustomTextfieldController } from "@cbex/form/textfield";
import { CustomDateTimePickerController } from "@cbex/form/datetimepicker";
import { UseFormReturn, useWatch } from "react-hook-form";
import { NewExamDetailsDefaultValues } from "../utils/ExamDetailsDefaultValues";
import { ActivityTypeAutoComplete } from "../composites/Refactor/ActivityTypeAutoComplete";
import { ExamAddressesAutoComplete } from "../composites/../composites/Refactor/ExamAddressesAutoComplete";
import ExamResourcesAutoComplete from "../composites/Refactor/ExamResourcesAutoComplete";
import {
  ResourcesByActivityTypeIdAndEmployeeIdRow,
  RelationAddress,
  GetActivityTypeDocument,
  PlanningActivityType,
  ResourceListByActivityTypeIdDocument,
  ResourcesByActivityTypeIdRow,
} from "@cbex/data-access";
import { useQuery } from "@apollo/client";
import { useWindowDimensions } from "@cbex/utils/hooks";
import { CustomTextField } from "@cbex/ui/input";
import { CustomDialog } from "@cbex/ui/modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomTypographyComponent } from "@cbex/ui/text";
import { useTheme } from "@cbex/utils/theme";

interface NewExamFormProps {
  form: UseFormReturn<NewExamDetailsDefaultValues>;
  onActivityChange: (outGoingActivity: any) => void;
}

const removeInArray = (
  a: PlanningActivityType[],
  b: PlanningActivityType[]
) => {
  const newArrr = a.filter(
    (ar) => !b.find((rm) => rm.activityTypeID === ar.activityTypeID)
  );
  return newArrr;
};

const intersection = (a: readonly any[], b: readonly any[]) =>
  a.filter((value: any) =>
    b.find(
      (bt) => bt.activityTypeID.toString() === value.activityTypeID.toString()
    )
  );

const not = (a: readonly any[], b: readonly any[]) =>
  a.filter((value: any) => b.indexOf(value) === -1);

const NewExamForm = ({ form, onActivityChange }: NewExamFormProps) => {
  const windowSize = useWindowDimensions();
  const t = useTranslations();
  const { control, setValue, formState } = form;
  const theme = useTheme();
  const [resources, setResources] = React.useState<
    ResourcesByActivityTypeIdAndEmployeeIdRow[]
  >([]);
  const localChanges = useWatch({
    control,
    name: ["activityTypeIDs"],
  });
  const [planningActivityTypes, setPlanningActivityTypes] = React.useState<
    PlanningActivityType[]
  >([]);
  const [locations, setLocations] = React.useState<RelationAddress[]>([]);
  const [activityTypeIDs, setActivityTypeIDs] = React.useState([]);

  // left hand side  and also part of the search
  const [localPlanningActivityTypes, setLocalPlanningActivityTypes] =
    React.useState<PlanningActivityType[]>([]);
  // right hand side for dispalying
  const [selectedActivityTypes, setSelectedActivityTypes] = React.useState<
    PlanningActivityType[]
    //@ts-ignore
  >([]);
  const [searchText, setSearchText] = React.useState<string | null>(null);

  const [checked, setChecked] = React.useState<readonly any[]>([]);
  const [
    currentDeletePendingActivityType,
    setCurrentDeletePendingActivityType,
  ] = React.useState<PlanningActivityType[]>([]);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    React.useState(false);

  const leftChecked =
    planningActivityTypes && planningActivityTypes.length > 0
      ? intersection(checked, planningActivityTypes)
      : [];

  const handleToggle = (value: any) => () => {
    if (checked.length > 0) {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    } else {
      setChecked([value]);
    }
  };

  const handleCancel = () => {
    setOpenDeleteConfirmDialog(false);
    setCurrentDeletePendingActivityType(null);
  };

  const handleCheckedRight = () => {
    const newSelected = selectedActivityTypes.concat(...leftChecked);
    setSelectedActivityTypes(newSelected);
    //@ts-ignore
    setValue("activityTypeIDs", newSelected);
    const newLocal = removeInArray(planningActivityTypes, newSelected);
    setLocalPlanningActivityTypes(newLocal);
    setChecked(not(checked, leftChecked));
    setSearchText(null);
  };

  const filter = (incomingVaL) => {
    setSearchText(incomingVaL);

    const temp = planningActivityTypes.filter((val: PlanningActivityType) =>
      val.name.toLowerCase().includes(incomingVaL)
    );
    const temp2 = removeInArray(temp, selectedActivityTypes);
    setLocalPlanningActivityTypes(temp2);
  };

  const handleDisabledFilter = (option, inputArray): boolean => {
    if (inputArray.length <= 0) {
      return false;
    }

    if (option) {
      const findCometenceID = inputArray[0].competenceID;

      if (findCometenceID === option.competenceID) return false;
      else {
        return true;
      }
    }
  };

  const handleDelete = () => {
    const sortlocalPlanningActivityTypes = localPlanningActivityTypes.concat(
      currentDeletePendingActivityType
    );

    const newActivityArrayAfterDelete = removeInArray(
      selectedActivityTypes,
      currentDeletePendingActivityType
    );

    sortlocalPlanningActivityTypes.sort(function (a, b) {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    setLocalPlanningActivityTypes(sortlocalPlanningActivityTypes);

    setSelectedActivityTypes(newActivityArrayAfterDelete);
    setValue(
      "activityTypeIDs",
      //@ts-ignore
      newActivityArrayAfterDelete
    );
    setChecked(not(checked, currentDeletePendingActivityType));

    if (newActivityArrayAfterDelete.length <= 0) {
      setValue("resources", []);
      setValue("locationId", null);
    }

    //@ts-ignore
    setCurrentDeletePendingActivityType([]);
    setOpenDeleteConfirmDialog(false);
  };

  const { data: activityTypeData, loading: activityTypeDataLoading } = useQuery(
    GetActivityTypeDocument,
    {
      variables: {},
      fetchPolicy: "network-only",
    }
  );

  const { data: resourcesData, loading: resourcesLoading } = useQuery(
    ResourceListByActivityTypeIdDocument,
    {
      variables: {
        activityTypeID:
          (localChanges &&
            localChanges[0] &&
            localChanges[0] &&
            //@ts-ignore
            localChanges[0].map((item) => item.activityTypeID)) ||
          [],
      },
      fetchPolicy: "network-only",
    }
  );

  React.useEffect(() => {
    if (
      !activityTypeDataLoading &&
      activityTypeData &&
      activityTypeData.ActivityTypes &&
      activityTypeData.ActivityTypes.length > 0
    ) {
      const newActivityType = activityTypeData.ActivityTypes.map(
        (item: PlanningActivityType) => ({
          activityTypeID: item.activityTypeID,
          name: item.name,
          competenceID: item.competenceID,
          settings: item.settings,
        })
      );

      const activityTypeIds = activityTypeData.ActivityTypes.map(
        (item) => item.activityTypeID
      );
      setPlanningActivityTypes([...newActivityType]);

      setActivityTypeIDs([...activityTypeIds]);
      setLocalPlanningActivityTypes(
        removeInArray([...newActivityType], selectedActivityTypes)
      );
    } else {
      setPlanningActivityTypes([]);
      setActivityTypeIDs([]);
      setLocalPlanningActivityTypes([]);
    }
  }, [activityTypeData, activityTypeDataLoading]);

  React.useEffect(() => {
    if (
      resourcesData &&
      resourcesData.ResourceListByActivityTypeID &&
      resourcesData.ResourceListByActivityTypeID.length > 0
    ) {
      const resourceList: ResourcesByActivityTypeIdAndEmployeeIdRow[] =
        resourcesData.ResourceListByActivityTypeID.map(
          //@ts-ignore
          (resourceInput: ResourcesByActivityTypeIdRow) => ({
            employeeID: resourceInput.employeeID,
            name: resourceInput.name,
            resourceID: resourceInput.resourceID,
          })
        );

      setResources(resourceList);
    } else {
      setResources([]);
    }
  }, [resourcesData, resourcesLoading]);

  React.useEffect(() => {
    if (localChanges) {
      onActivityChange(localChanges[0]);
    }
  }, [localChanges, onActivityChange]);

  const customList = (
    title: React.ReactNode,
    items: readonly PlanningActivityType[],
    isSelectedActivitiesList: Boolean
  ) => (
    <Card sx={{ width: 500 }}>
      <CardHeader sx={{ px: 2, py: 1 }} subheader={title} />
      {!isSelectedActivitiesList && (
        <CustomTextField
          variant="filled"
          label={t("labels.searchActivityType")}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            filter(event.target.value.toLocaleLowerCase());
          }}
          value={searchText}
          type="search"
        />
      )}
      <Divider />
      {items && items.length > 0 && (
        <List
          sx={{
            width: 490,
            maxHeight: 190,
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value: any) => {
            const labelId = `transfer-list-all-item-${value.name}-label`;

            return (
              <ListItemButton
                disableRipple
                key={value.activityTypeID}
                disabled={
                  !isSelectedActivitiesList
                    ? handleDisabledFilter(value, selectedActivityTypes) ||
                      handleDisabledFilter(value, checked)
                    : false
                }
                onClick={
                  !isSelectedActivitiesList ? handleToggle(value) : () => {}
                }
              >
                <ListItemIcon>
                  {!isSelectedActivitiesList && (
                    <Checkbox
                      checked={checked.includes(value)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                      style={{ padding: 2 }}
                      size="small"
                      disabled={
                        handleDisabledFilter(value, selectedActivityTypes) ||
                        handleDisabledFilter(value, checked)
                      }
                    />
                  )}
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value.name}`} />
                {isSelectedActivitiesList && (
                  <ListItemSecondaryAction>
                    {" "}
                    <Tooltip title={t("labels.delete")}>
                      {/* span added so as to activate the tooltip for disabled button on hover */}
                      <span>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setCurrentDeletePendingActivityType([value]);
                            setOpenDeleteConfirmDialog(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </ListItemButton>
            );
          })}
          <ListItem />
        </List>
      )}

      {openDeleteConfirmDialog && (
        <CustomDialog
          open={openDeleteConfirmDialog}
          leftButtonHeading={t("labels.cancel")}
          rightButtonHeading={t("labels.delete")}
          handleCancel={handleCancel}
          handleClosePressed={handleDelete}
          heading={t("common.deleteActivityConfirm")}
        />
      )}
    </Card>
  );

  return (
    <Paper>
      <Stack
        overflow={"auto"}
        height={windowSize.height - 180}
        direction={"row"}
        padding={2}
      >
        <Stack direction="column" spacing={2}>
          <FormControl sx={{ width: "500px" }}>
            <CustomDateTimePickerController
              label={t("labels.startDate")}
              name="startDate"
              control={control}
            />
          </FormControl>
          <FormControl variant="filled">
            <Grid>
              <Grid item>
                {customList("", localPlanningActivityTypes, false)}
              </Grid>
            </Grid>
          </FormControl>
          <FormControl variant="filled">
            <ExamAddressesAutoComplete
              name={"locationId"}
              control={control}
              locations={locations}
              //@ts-ignore
              inputSelectedActivityTypes={
                (localChanges &&
                  localChanges[0] &&
                  //@ts-ignore
                  localChanges[0]) ||
                []
              }
              //@ts-ignore
              form={form}
              // addresType="EXAM"
            />
          </FormControl>
          <FormControl variant="filled">
            <ExamResourcesAutoComplete
              name={"resources"}
              control={control}
              errors={form.formState.errors["resources"]}
              resources={resources}
              label="examiner"
              //@ts-ignore
              inputSelectedActivityTypes={
                (localChanges &&
                  localChanges[0] &&
                  //@ts-ignore
                  localChanges[0]) ||
                []
              }
            />
          </FormControl>
          <FormControl variant="outlined" sx={{ width: "500px" }}>
            <CustomTextfieldController
              control={control}
              name="notes"
              id="outlined-adornment-note"
              multiline
              label={t("labels.note")}
              rows={8}
              fullWidth
            />
          </FormControl>
        </Stack>
        {/* Activity transfer list button */}
        <Stack direction="column" marginTop={48} marginLeft={4}>
          <Tooltip
            title={
              leftChecked.length === 0
                ? t("labels.noActivitySelected")
                : t("common.addActivity")
            }
          >
            {/* span added so as to activate the tooltip for disabled button on hover */}
            <span>
              <Button
                sx={{ my: 0.5, height: 30 }}
                variant="contained"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
              >
                &gt;
              </Button>
            </span>
          </Tooltip>
        </Stack>

        <Stack direction={"column"} p={2}>
          <Grid>
            <Grid item>
              {customList(
                t("common.mappedActivityList"),
                selectedActivityTypes,
                true
              )}
            </Grid>
          </Grid>
          {form.formState &&
            form.formState.errors &&
            form.formState.errors["activityTypeIDs"] && (
              <Stack>
                <CustomTypographyComponent color={theme.appColors.error}>
                  {form.formState.errors["activityTypeIDs"].message}
                </CustomTypographyComponent>
              </Stack>
            )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default NewExamForm;
