import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";

interface CustomTabProps {
  id: string;
  tabPanels: string[];
  children?: React.ReactNode;
  onValueChanged: (value: number) => void;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Stack>{children}</Stack>}
    </div>
  );
};

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});

export const CustomTab = ({
  id,
  tabPanels,
  children,
  onValueChanged,
}: CustomTabProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onValueChanged(newValue);
  };

  return (
    <Stack id={id} height="100%" width="100%">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          {tabPanels &&
            tabPanels.map((tabPanel: string, index: number) => {
              if (tabPanel && tabPanel !== "") {
                return (
                  <Tab key={index} label={tabPanel} {...a11yProps(index)} />
                );
              } else {
                return undefined;
              }
            })}
        </Tabs>
      </Box>
      {children}
    </Stack>
  );
};

export default CustomTab;
