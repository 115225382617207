import FileDownload from "js-file-download";
import axios from "axios";
import dayjs from "dayjs";

export const mapCbexStatusColours = (status: string, theme: any): string => {
  switch (status) {
    case "Requested":
      return theme.appColors.customColors.cbexRequested;
    case "Confirmed":
      return theme.appColors.customColors.cbexConfirmed;
    default:
      return theme.appColors.customColors.cbexResultsCollected;
  }
};
interface DownloadInputParams {
  templateType: string;
  pdfObject: JSON | any;
  downloadName?: string;
}

interface DownloadZipInputParams {
  templateType: string;
  pdfObject: any[];
  downloadName?: string;
}

export const downLoadCommunisPDF = (input: DownloadInputParams) => {
  axios
    .post("/api/communis", JSON.stringify(input.pdfObject), {
      params: { template: input.templateType },
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    })
    .then((res: any) => {
      const pdfFile = new Blob([res.data], {
        type: "application/pdf",
      });
      if (pdfFile) {
        FileDownload(pdfFile, `${input.downloadName}.pdf`);
      }
    })
    .catch((FEerror: any) => console.log("FE error", FEerror));
};

export const downLoadCommunisZip = (input: DownloadZipInputParams) => {
  axios
    .post("/api/compressDownload", JSON.stringify(input), {
      params: { template: input.templateType },
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    })
    .then((res: any) => {
      const zipfile = new Blob([res.data], {
        type: "application/zip",
      });
      if (zipfile) {
        FileDownload(
          zipfile,
          `certificaten-${dayjs(input.pdfObject[0].startDate).format(
            "DD-MM-YYYY"
          )}.zip`
        );
      }
    })
    .catch((FEerror: any) => FEerror);
};

const checkUppercase = (str: string): boolean => {
  for (let i = 0; i < str.length; i++) {
    if (
      str.charAt(i) == str.charAt(i).toUpperCase() &&
      str.charAt(i).match(/[a-z]/i)
    ) {
      return true;
    }
  }
  return false;
};

export const validPassword = (password: string): boolean => {
  const isUpperCase = checkUppercase(password);
  if (password && password.length >= 8 && isUpperCase) {
    return true;
  } else {
    return false;
  }
};

export const isEmail = (email: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
