import React from "react";
import { ActivityFilterComponent } from "./ActivityFilterComponent";
import { ActivityCard } from "./ActivityCard";
import { useWindowDimensions } from "@cbex/utils/hooks";
import dayjs from "dayjs";
import * as yup from "yup";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from "@mui/icons-material/Save";
import {
  GetExamOverviewWithFiltersDocument,
  CbexPortalExamOverviewWithFilterOptionsRow,
  AddExamDocument,
  NewExamDetailParams,
  CbexMomentExam,
  ExamByMomentIdDocument,
} from "@cbex/data-access";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { CustomDialog, CustomModal } from "@cbex/ui/modal";
import { IconButton, Button, Stack } from "@mui/material";
import { useTranslations } from "next-intl";
import {
  NewExamDetailsDefaultValues,
  getNewExamDetailsDefaultValues,
} from "../utils/ExamDetailsDefaultValues";
import NewExamForm from "../forms/NewExamForm";
import { SnackBar } from "@cbex/ui/snack-bar";
import CreateRelationAddressForm from "../forms/OrganizationAddressForm";
import ExamDetailsComposite from "./ExamDetailsComposite";

export interface ActivityFilterValues {
  dateFrom: any;
  dateTill: any;
}

export const ActivityOverviewComposite = () => {
  const [examDataList, setExamDataList] = React.useState<
    CbexPortalExamOverviewWithFilterOptionsRow[]
  >([]);
  const windowSize = useWindowDimensions();
  const [activityConfig, setactivityConfig] = React.useState();
  const [selectedMomentExam, setSelectedMomentExam] =
    React.useState<CbexMomentExam>();
  const t = useTranslations();
  const [openAddressModal, setOpenAddressModal] =
    React.useState<boolean>(false);
  const [selectedMomentID, setSelectedMomentExamID] = React.useState("");
  const [editTitle, setEditTitle] = React.useState("");

  const addOrganisationAddressRef = React.useRef();

  const examSchema = yup.object().shape({
    startDate: yup.date().required(t("labels.required")),
    locationId: yup.string().required(t("labels.required")),
    activityTypeIDs: yup.array().min(1, t("labels.required")),
    resources: yup.array().test(
      "activityTypeIDs",
      t("labels.required"),

      (val, context) => {
        //@ts-ignore
        const { activityTypeIDs } = context.parent;
        if (
          activityTypeIDs[0].settings.allowSelectResource.toString() === "true"
        ) {
          //return yup.array().required().min(1, t("labels.required"));
          if (val.length === 0) {
            return context.createError({ message: t("labels.required") });
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    ),
  });
  const newExamForm = useForm<NewExamDetailsDefaultValues>({
    mode: "onSubmit",
    defaultValues: getNewExamDetailsDefaultValues(),
    //@ts-ignore
    resolver: yupResolver(examSchema),
  });

  const {
    getValues,
    reset: restExam,
    handleSubmit: handleExamCreatedSubmit,
  } = newExamForm;

  const [openExamModal, setOpenExamModal] = React.useState<boolean>(false);
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] =
    React.useState<boolean>(false);
  const [snackBar, setSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [filterValues, setFilterValues] = React.useState<ActivityFilterValues>({
    dateFrom: dayjs().subtract(3, "month"),
    dateTill: dayjs().add(3, "month"),
  });

  const {
    data: getExamByMomentIDData,
    loading: getExamByMomentIDDataLoading,
    refetch: refetchExamByMomentIDData,
  } = useQuery(ExamByMomentIdDocument, {
    variables: {
      momentID: selectedMomentID ? selectedMomentID : "",
    },
    fetchPolicy: "network-only",
    skip: selectedMomentID === "",
  });

  React.useEffect(() => {
    if (
      !getExamByMomentIDDataLoading &&
      getExamByMomentIDData &&
      getExamByMomentIDData.ExamByMomentID
    ) {
      setSelectedMomentExam(getExamByMomentIDData.ExamByMomentID);

      setEditTitle(
        `${t("labels.currentExam")}: ${dayjs(
          getExamByMomentIDData.ExamByMomentID.moment.startDate
        ).format("DD/MM/YYYY HH:mm")} ${
          getExamByMomentIDData.ExamByMomentID.moment.location.city
        }`
      );
    } else {
      setSelectedMomentExam(undefined);
      setEditTitle(t("labels.currentExam"));
    }
  }, [getExamByMomentIDDataLoading, getExamByMomentIDData, t]);

  const {
    data: examActivityData,
    loading: examActivityLoading,
    refetch: refetchExamOverviewWithFilters,
  } = useQuery(GetExamOverviewWithFiltersDocument, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    errorPolicy: "ignore",

    variables: {
      filter: {
        dateFrom: filterValues.dateFrom,
        dateTill: filterValues.dateTill,
      },
    },
  });

  React.useEffect(() => {
    if (
      !examActivityLoading &&
      examActivityData &&
      examActivityData.ExamOverviewWithFilters &&
      examActivityData.ExamOverviewWithFilters.length > 0
    ) {
      setExamDataList(examActivityData.ExamOverviewWithFilters);
    } else {
      setExamDataList([]);
    }
  }, [examActivityLoading, examActivityData]);

  const handleFilterUpdate = (data: ActivityFilterValues) => {
    setFilterValues(data);
  };
  const handleModalOpenState = async (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      setOpenUnsavedChangesDialog(true);
    }
  };

  const examModalClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason !== "backdropClick") {
      if (openExamModal) {
        restExam({}, { keepDefaultValues: false });
        refetchExamByMomentIDData();
        setSelectedMomentExam(null);
        setSelectedMomentExamID("");
        setEditTitle(t("labels.exam"));

        setOpenUnsavedChangesDialog(false);
      }
      setOpenExamModal(!openExamModal);
    }
  };

  const onMutationComplete = (item) => {
    setSelectedMomentExamID(item.NewExam);
    restExam(null, { keepDefaultValues: false });
    setTimeout(() => {
      setSnackBarMessage(t("feedback.createSuccess"));
      setSnackBar(true);
      setSeverity("success");
      refetchExamByMomentIDData();
    }, 1000);
  };

  const [addExam] = useMutation(AddExamDocument, {
    onCompleted: onMutationComplete,
  });

  const handleExamCreated = (examData: NewExamDetailParams) => {
    if (!_.isEmpty(newExamForm.formState.errors)) {
      setSnackBarMessage(t("feedback.missingValues"));
      setSnackBar(true);
      setSeverity("error");
      return;
    }

    if (
      examData &&
      examData.activityTypeIDs &&
      examData.activityTypeIDs.length > 0
    ) {
      addExam({
        variables: {
          input: {
            activityTypeIDs:
              examData.activityTypeIDs &&
              examData.activityTypeIDs.length > 0 &&
              //@ts-ignore
              examData.activityTypeIDs.map((item) => item.activityTypeID),
            startDate: examData.startDate,
            notes: examData.notes,
            locationId: examData.locationId,
            resources:
              examData.resources && examData.resources.length > 0
                ? //@ts-ignore
                  examData.resources.map((res) => res.resourceID)
                : null,
          },
        },
      });
    } else {
      setSnackBarMessage(t("feedback.activtiesNotFound"));
      setSnackBar(true);
      setSeverity("error");
    }
  };

  const onModalClosed = () => {
    setOpenAddressModal(false);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBar(false);
  };

  const handleOnError = (errors: any) => {
    if (errors) {
      setSnackBar(true);
      setSeverity("error");
      setSnackBarMessage(t("feedback.missingValues"));
    }
  };

  const onSubmit = () => {
    if (addOrganisationAddressRef && addOrganisationAddressRef.current) {
      //@ts-ignore
      addOrganisationAddressRef.current.onSubmit();
    }
  };
  const handleOnCreateComplete = () => {
    setOpenAddressModal(false);
    setSnackBarMessage(t("feedback.createSuccess"));
    setSnackBar(true);
    setSeverity("success");
  };

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event === null) {
      setExamDataList(examActivityData.ExamOverviewWithFilters);
    } else {
      setExamDataList(
        examActivityData.ExamOverviewWithFilters.filter(
          (item) =>
            item.moment.location.city
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            item.moment.location.street
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            item.activity.find((act) =>
              act.name.toLowerCase().includes(event.target.value.toLowerCase())
            )
        )
      );
    }
  };

  return (
    <Stack
      width="100%"
      id="ActivityOverviewComposite"
      padding={4}
      spacing={4}
      maxWidth={1200}
    >
      <ActivityFilterComponent
        onNewPressed={examModalClose}
        isActivityOverview={true}
        onSearchChanged={handleSearchTextChange}
        inputValues={filterValues}
        isNotCandidateHistory={true}
        onDataChanged={handleFilterUpdate}
      />
      <Stack
        height={{ md: windowSize.height - 180, xs: windowSize.height - 300 }}
        spacing={2}
        width="100%"
        overflow={"auto"}
      >
        {!examActivityLoading &&
          examActivityData &&
          examDataList &&
          examDataList.length > 0 &&
          examDataList.length > 0 &&
          examDataList.map(
            (
              item: CbexPortalExamOverviewWithFilterOptionsRow,
              index: number
            ) => (
              <ActivityCard
                refetch={refetchExamOverviewWithFilters}
                filterVar={filterValues}
                isActivity={true}
                isResultsCollected={
                  item &&
                  item.status &&
                  item.status.toString().toLowerCase() === "resultscollected"
                    ? true
                    : false
                }
                onExamPressed={(outGoingMomentID) => {
                  setSelectedMomentExamID(outGoingMomentID);
                  setOpenExamModal(true);
                }}
                inputData={item}
                key={index}
              />
            )
          )}
      </Stack>

      {openExamModal && (
        <CustomModal
          openModal={openExamModal}
          closeHandler={
            selectedMomentID === "" ? examModalClose : handleModalOpenState
          }
          modalTitle={selectedMomentID === "" ? t("common.newExam") : editTitle}
        >
          <Stack padding={1} width={"100%"}>
            <Stack justifyContent={"space-between"} direction={"row"} p={2}>
              {selectedMomentID === "" &&
                activityConfig &&
                activityConfig[0] &&
                //@ts-ignore
                activityConfig[0].settings &&
                //@ts-ignore
                activityConfig[0].settings.allowNewLocation && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setOpenAddressModal(true);
                    }}
                    sx={{ width: 200, height: 30 }}
                  >
                    {t("common.newLocation")}
                  </Button>
                )}
              <Stack>{/* for button to be at end always */}</Stack>

              {selectedMomentID === "" && (
                <IconButton
                  sx={{ width: 15 }}
                  type="submit"
                  onClick={handleExamCreatedSubmit(
                    () => handleExamCreated(getValues()),
                    (err: any) => handleOnError(err)
                  )}
                >
                  <SaveIcon color="primary" />
                </IconButton>
              )}
            </Stack>

            {selectedMomentID === "" && (
              <Stack>
                <form>
                  <NewExamForm
                    form={newExamForm}
                    onActivityChange={(val) => setactivityConfig(val)}
                  />
                </form>
              </Stack>
            )}
            {selectedMomentID !== "" && selectedMomentExam && (
              <ExamDetailsComposite
                refetchOverviewData={() => {
                  refetchExamByMomentIDData();
                  refetchExamOverviewWithFilters();
                }}
                examData={selectedMomentExam}
              />
            )}
          </Stack>
        </CustomModal>
      )}

      {snackBar && (
        <SnackBar
          openState={snackBar}
          //@ts-ignore
          severity={severity}
          message={snackBarMessage}
          //@ts-ignore
          onClose={handleClose}
        ></SnackBar>
      )}

      {openAddressModal && (
        <CustomModal
          modalTitle={t("common.addNewAddress")}
          openModal={openAddressModal}
          minWidth={600}
          closeHandler={onModalClosed}
        >
          <Stack alignItems={"center"} spacing={2} padding={1}>
            <CreateRelationAddressForm
              addressID=""
              onCreateComplete={handleOnCreateComplete}
              ref={addOrganisationAddressRef}
            />
          </Stack>
          <Stack alignItems="flex-end">
            <Button color="primary" variant="contained" onClick={onSubmit}>
              {t("common.submit")}
            </Button>
          </Stack>
        </CustomModal>
      )}

      {openUnsavedChangesDialog && (
        <CustomDialog
          open={openUnsavedChangesDialog}
          leftButtonHeading={t("labels.cancel")}
          rightButtonHeading={t("common.confirm")}
          handleCancel={() => {
            setOpenUnsavedChangesDialog(false);
          }}
          handleClosePressed={examModalClose}
          heading={t("feedback.closeConfirm")}
        />
      )}
    </Stack>
  );
};

export default ActivityOverviewComposite;
