/* eslint-disable func-style */
import * as React from "react";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import { useTheme } from "@cbex/utils/theme";
import { CountryList, GoogleMapsPlacesAutoComplete } from "../index";
import { SnackBar } from "@cbex/ui/snack-bar";
import { CustomTextField } from "@cbex/ui/input";
import {
  AddAddressDocument,
  GetGoogleMapsPlaceDetailsDocument,
} from "@cbex/data-access";
import { useWindowDimensions } from "@cbex/utils/hooks";
import { useTranslations } from "next-intl";
import { useMutation, useQuery } from "@apollo/client";
interface CreatedOrganizationAddressFormError {
  street: string;
  city: string;
  postalcode: string;
}

interface RelationAddressFormProps {
  addressID: string;
  onCreateComplete?: any;
  dataAdded?: any;
}
interface State {
  street: string;
  number: string;
  name: string;
  postalcode: string;
  city: string;
  country: string;
}

interface GoogleMapsAutoCompleteQueryVariables {
  placeID: string;
  sessionToken: any;
}

export const CreateRelationAddressForm = React.forwardRef(
  (props: RelationAddressFormProps, ref) => {
    const { addressID, onCreateComplete, dataAdded } = props;
    const [values, setValues] = React.useState<State>({
      name: "",
      number: "",
      street: "",
      postalcode: "",
      city: "",
      country: "NL",
    });
    const { height } = useWindowDimensions();
    const t = useTranslations();
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState(false);
    const [addressSearchName, setAddressSearchName] = React.useState("");
    const [
      googleMapsAutoCompletequeryVariables,
      setGoogleMapsAutoCompletequeryVariables,
    ] = React.useState<GoogleMapsAutoCompleteQueryVariables>({
      placeID: "",
      sessionToken: "",
    });
    const {
      data: GoogleMapsAutoCompleteData,
      loading: GoogleMapsAutoCompleteLoading,
    } = useQuery(GetGoogleMapsPlaceDetailsDocument, {
      variables: {
        placeID: googleMapsAutoCompletequeryVariables.placeID,
        sessionToken: googleMapsAutoCompletequeryVariables.sessionToken,
      },
    });

    const onMutationComplete = (item) => {
      if (item && addressID === "") {
        onCreateComplete(item, values);
      }
      setSnackBar(true);
      setSeverity("success");
      setSnackBarMessage(
        addressID === ""
          ? "Address successfully created"
          : "Address successfully updated"
      );
    };

    const [addAddress, { error: error1 }] = useMutation(AddAddressDocument, {
      onCompleted: onMutationComplete,
      refetchQueries: [],
    });

    React.useEffect(() => {
      if (
        !GoogleMapsAutoCompleteLoading &&
        GoogleMapsAutoCompleteData &&
        GoogleMapsAutoCompleteData.GooglePlacesDetails
      ) {
        const { GooglePlacesDetails } = GoogleMapsAutoCompleteData;
        setValues({
          ...values,
          city: GooglePlacesDetails.city,
          country: GooglePlacesDetails.country,
          number: GooglePlacesDetails.number,
          postalcode: GooglePlacesDetails.postalCode,
          street: GooglePlacesDetails.street,
        });
        checkRequiredValues(GoogleMapsAutoCompleteData.GooglePlacesDetails);
      }
    }, [GoogleMapsAutoCompleteData, GoogleMapsAutoCompleteLoading]);

    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("");

    const checkRequiredValues = (val) => {
      if (val.street === "") {
        error.street = val.street !== "" ? "" : t("feedback.missingValues");
      } else {
        error.street = "";
      }
      if (val.city === "") {
        error.city = val.city !== "" ? "" : t("feedback.missingValues");
      } else {
        error.city = "";
      }
      if (val.postalcode === "") {
        error.postalcode =
          val.postalcode !== "" ? "" : t("feedback.missingValues");
      } else {
        error.postalcode = "";
      }
      setError({ ...error });
    };

    const theme = useTheme();

    React.useEffect(() => {
      if (dataAdded && addressID !== "") {
        setValues({
          name: dataAdded.name || "",
          number: dataAdded.number || "",
          street: dataAdded.street || "",
          postalcode: dataAdded.postalcode || "",
          city: dataAdded.city || "",
          country: dataAdded.country || "",
        });
        checkRequiredValues(dataAdded);
      }
    }, [dataAdded, addressID]);

    const [error, setError] =
      React.useState<CreatedOrganizationAddressFormError>({
        city: t("feedback.missingValues"),
        street: t("feedback.missingValues"),
        postalcode: t("feedback.missingValues"),
      });

    const handleChange =
      (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (prop === "city") {
          if (event.target.value === "") {
            error.city = t("feedback.missingValues");
          } else {
            error.city = "";
          }
        }
        if (prop === "street") {
          if (event.target.value === "") {
            error.street = t("feedback.missingValues");
          } else {
            error.street = "";
          }
        }
        if (prop === "postalcode") {
          if (event.target.value === "") {
            error.street = t("feedback.missingValues");
          } else {
            error.postalcode = "";
          }
        }

        setError({ ...error });
        setValues({ ...values, [prop]: event.target.value });
      };

    const handleCountrySelect = (event: any) => {
      setValues({ ...values, country: event.target.value });
    };

    const handleClose = (__event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      setSnackBar(false);
    };

    React.useImperativeHandle(ref, () => ({
      onSubmit() {
        setIsSubmitted(true);
        if (Object.values(error).some((item) => item.length > 0)) {
          setSnackBar(true);
          setSeverity("error");
          setSnackBarMessage(t("feedback.missingValues"));
        } else {
          if (addressID === "") {
            addAddress({
              variables: {
                input: {
                  name: values.name,
                  number: values.number,
                  street: values.street,
                  postalcode: values.postalcode,
                  city: values.city,
                  country: values.country,
                },
              },
            }).catch((errormsg) => console.log(errormsg));
          }
          {
            onCreateComplete(addressID, values);
          }
        }
      },
    }));

    return (
      <Box
        sx={{
          maxHeight: height - 60,
          overflowY: "auto",
          marginBottom: 5,

          width: "100%",
        }}
      >
        <Stack direction="column">
          <Stack padding={2}>
            <GoogleMapsPlacesAutoComplete
              disabled={false}
              onInputValueChanged={(params) => {
                setAddressSearchName(params);
              }}
              onValueChanged={(val) => {
                if (val) {
                  setGoogleMapsAutoCompletequeryVariables({
                    placeID: val.id,
                    sessionToken: val.sessionToken,
                  });
                }
              }}
              width={400}
              name={addressSearchName}
            />
            <FormHelperText id="filled-addressSearchName-helper-text">
              {t("labels.googleAutoCompleteHelpertext")}
            </FormHelperText>
          </Stack>

          <Stack direction="row" marginTop={1} spacing={1} marginBottom={2}>
            <FormControl
              sx={{ width: "250px", marginLeft: 2 }}
              variant="outlined"
            >
              <CustomTextField
                id="outlined-adornment-name"
                label={t("labels.addressName")}
                variant="filled"
                value={values.name}
                controldisabled={false}
                onChange={handleChange("name")}
                aria-describedby="filled-name-helper-text"
              />
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2} width="100%" marginBottom={2}>
            <FormControl
              sx={{ width: "230px", marginLeft: 2 }}
              variant="outlined"
            >
              <CustomTextField
                id="outlined-adornment-street"
                value={values.street}
                controldisabled={false}
                error={isSubmitted && error.street.length > 0}
                onChange={handleChange("street")}
                aria-describedby="filled-street-helper-text"
                label={t("labels.street")}
                required
              />
              <FormHelperText
                sx={{
                  color: error.street ? theme.appColors.error : "",
                }}
                id="filled-street-helper-text"
              >
                {isSubmitted && error.street && error.street}
              </FormHelperText>
            </FormControl>
            <FormControl sx={{ width: "150px" }} variant="filled">
              <CustomTextField
                id="outlined-adornment-number"
                value={values.number}
                controldisabled={false}
                onChange={handleChange("number")}
                label={t("labels.number")}
                aria-describedby="filled-number-helper-text"
                required
              />
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2} width="100%" marginBottom={2}>
            <FormControl
              sx={{ width: "16%", marginLeft: 2 }}
              variant="outlined"
            >
              <CustomTextField
                id="outlined-adornment-postalcode"
                label={t("labels.postalCode")}
                controldisabled={false}
                value={values.postalcode}
                onChange={handleChange("postalcode")}
                error={isSubmitted && error.postalcode.length > 0}
                required
              />
              <FormHelperText
                sx={{
                  color: error.postalcode ? theme.appColors.error : "",
                }}
              >
                {isSubmitted && error.postalcode && error.postalcode}
              </FormHelperText>
            </FormControl>

            <FormControl
              sx={{ width: "240px", marginLeft: 2 }}
              variant="outlined"
            >
              <CustomTextField
                id="outlined-adornment-"
                label={t("labels.city")}
                controldisabled={false}
                value={values.city}
                error={isSubmitted && error.city.length > 0}
                onChange={handleChange("city")}
                required
              />
              <FormHelperText
                sx={{
                  color: error.city ? theme.appColors.error : "",
                }}
                id="filled-city-helper-text"
              >
                {isSubmitted && error.city && error.city}
              </FormHelperText>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2} width="100%" marginBottom={2}>
            <FormControl
              sx={{ width: "240px", marginLeft: 2 }}
              variant="filled"
            >
              <CountryList
                onValueChanged={(val: any) => handleCountrySelect(val)}
                inputValue={values.country}
              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <SnackBar
            openState={snackBar}
            //@ts-ignore
            severity={severity}
            message={snackBarMessage}
            //@ts-ignore
            onClose={handleClose}
          ></SnackBar>
        </Stack>
      </Box>
    );
  }
);

CreateRelationAddressForm.displayName = "CreateRelationAddressForm";

export default CreateRelationAddressForm;
