"use client";
import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { CustomInputLabel } from "@cbex/ui/text";
import { CustomSelectComponent } from "@cbex/ui/input";
import { MenuItem } from "@mui/material";
import { useTranslations } from "next-intl";
import { CountryListArray } from "../utils/Constants";

interface CountryListProps {
  controldisabled?: boolean;
  inputValue: string;
  onValueChanged: (value: any) => void;
}

export const CountryList = (props: CountryListProps) => {
  const { controldisabled, inputValue, onValueChanged } = props;
  const t = useTranslations();
  const [value, setValue] = React.useState(inputValue.trim());
  useEffect(() => {
    if (inputValue) {
      setValue(inputValue);
    }
  }, [inputValue]);

  const handleCountrySelected = (event: any) => {
    if (event) {
      setValue(event.target.value);
      onValueChanged(event);
    }
  };

  return (
    <Stack id="CountryList">
      <CustomInputLabel id="country-select-label">
        {t("labels.country")}
      </CustomInputLabel>
      <CustomSelectComponent
        labelId="country-select-label"
        id="address-select"
        controldisabled={controldisabled}
        value={value}
        variant="filled"
        //@ts-ignore
        onChange={(event: any) => handleCountrySelected(event)}
      >
        {
          //@ts-ignore
          CountryListArray.map((item, index) => (
            <MenuItem key={item.code} value={item.code}>
              {t(`country.${item.code.toUpperCase()}`)}
            </MenuItem>
          ))
        }
      </CustomSelectComponent>
    </Stack>
  );
};
export default CountryList;
