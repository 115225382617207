import { Paper, Stack } from "@mui/material";
import React from "react";
import { CustomModal } from "@cbex/ui/modal";
import { useTranslations } from "next-intl";
import { useWindowDimensions } from "@cbex/utils/hooks";

import {
  CandidatesWithResultsByMomentIdDocument,
  CbexPortalCandidatesWithResultsByMomentIdRow,
  GetCertificateDocumentDataDocument,
  CbexPortalCandidatesWithFiltersRow,
} from "@cbex/data-access";
import dayjs from "dayjs";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { DataGridComponent } from "@cbex/ui/table";
import { downLoadCommunisPDF } from "../utils";
import DownloadIcon from "@mui/icons-material/Download";

import { CustomTypographyComponent } from "@cbex/ui/text";

interface CandidateOverviewModalProps {
  onModalOpen: boolean;
  onModalClose: () => void;
  city: string;
  startDate: string;
  momentID: string;
}

export const CandidateOverviewModal = (props: CandidateOverviewModalProps) => {
  const actionStates = ["PASSED", "FAILED"];

  const { onModalClose, onModalOpen, startDate, city, momentID } = props;
  const t = useTranslations();
  const windowSize = useWindowDimensions();
  const [currentCandidate, setCurrentCandidate] =
    React.useState<CbexPortalCandidatesWithFiltersRow>();
  const [candidateData, setCandidateData] = React.useState<
    CbexPortalCandidatesWithResultsByMomentIdRow[]
  >([]);

  console.log("candidateData", candidateData);

  const candidateColumnData: any[] = [
    {
      field: "candidateID",
      headerName: t("id"),
    },
    {
      field: "initials",
      headerName: t("labels.initials"),
      width: 80,
      editable: false,
      sortable: false,
    },
    {
      field: "infix",
      headerName: t("labels.infix"),
      flex: 0.1,
      sortable: true,
      editable: false,
    },
    {
      field: "lastName",
      headerName: t("labels.lastName"),
      flex: 0.3,
      sortable: true,
      editable: false,
    },
    {
      field: "dateOfBirth",
      headerName: t("labels.dob"),
      width: 150,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <div style={{ width: "100%" }}>
          <CustomTypographyComponent>
            {
              //@ts-ignore
              params.value ? dayjs(params.value).format("DD-MM-YYYY") : ""
            }
          </CustomTypographyComponent>
        </div>
      ),
      //@ts-ignore
      valueFormatter: ({ value }) => dayjs(value).format("DD-MM-YYYY"),
    },
    {
      field: "emailAddress",
      headerName: t("labels.email"),
      flex: 0.3,
      sortable: false,
      editable: false,
    },
    {
      field: "notes",
      headerName: t("labels.note"),
      flex: 0.3,
      sortable: false,
      editable: false,
    },
    {
      field: "result",
      headerName: t("labels.result"),
      width: 120,
      sortable: false,
      editable: false,
      valueGetter: ({ value }) => t(`value.${value}`),
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("labels.actions"),
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={0}
          disabled={!actionStates.includes(params.row.result)}
          icon={
            <DownloadIcon
              titleAccess={t("labels.downloadCertificate")}
              //@ts-ignore
              color={
                actionStates.includes(params.row.result)
                  ? "primary"
                  : "disabled"
              }
            />
          }
          label={t("labels.downloadCertificate")}
          onClick={() => handleDownloadClick(params)}
        />,
      ],
    },
  ];

  const { data: candidateDataList, loading: candidateDataListLoading } =
    useQuery(CandidatesWithResultsByMomentIdDocument, {
      variables: {
        momentID: momentID,
      },
      fetchPolicy: "network-only",
    });

  React.useEffect(() => {
    if (
      !candidateDataListLoading &&
      candidateDataList &&
      candidateDataList.CandidatesWithResultsByMomentID
    ) {
      setCandidateData(candidateDataList.CandidatesWithResultsByMomentID);
    } else {
      setCandidateData(undefined);
    }
  }, [candidateDataList, candidateDataListLoading]);

  const [
    getCertificateDocumentData,
    { data: CertificateDocumentData, loading: CertificateDocumentDataLoading },
  ] = useLazyQuery(GetCertificateDocumentDataDocument, {
    variables: {
      ids: [],
    },
    fetchPolicy: "network-only",
    //@ts-ignore
  });

  const handleDownloadClick = (params: GridRowParams) => {
    setCurrentCandidate(params.row);
    getCertificateDocumentData({
      variables: {
        ids: [params.row.candidateID],
      },
    });
  };

  React.useEffect(() => {
    if (
      CertificateDocumentData &&
      CertificateDocumentData.CertificateDocumentData &&
      CertificateDocumentData.CertificateDocumentData.length > 0
    ) {
      downLoadCommunisPDF({
        downloadName: "cbexCertificate-download",
        pdfObject: {
          ...CertificateDocumentData.CertificateDocumentData[0],
          score: currentCandidate.score,
        },
        templateType:
          currentCandidate.result === "PASSED"
            ? CertificateDocumentData.CertificateDocumentData[0].activity
                .properties.template
            : "examResultFailPDF",
      });
    }
  }, [CertificateDocumentData]);

  const handleModalClose = () => {
    if (onModalClose) {
      onModalClose();
    }
  };

  return (
    <Stack id="CandidateOverviewModal">
      <CustomModal
        modalTitle={
          `${t("labels.currentExam")}` +
          ": " +
          `${dayjs(startDate).format("DD/MM/YYYY HH:mm")} ${city}`
        }
        openModal={onModalOpen}
        minWidth={900}
        closeHandler={handleModalClose}
      >
        <Paper>
          {candidateDataList && (
            // @ts-ignore
            <DataGridComponent
              getCustomID={(row) => row.candidateID}
              enableCheckbox={false}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    candidateID: false,
                  },
                },
              }}
              columns={candidateColumnData}
              searchenabled={true}
              exportEnabled
              //density="compact"
              rowHeight={60}
              rowData={candidateData}
              height={windowSize.height - 160}
              width={"100%"}
            />
          )}
        </Paper>
      </CustomModal>
    </Stack>
  );
};

export default CandidateOverviewModal;
