import { Stack } from "@mui/system";
import React from "react";
import { ActivityFilterComponent } from "./ActivityFilterComponent";
import { ActivityCard } from "./ActivityCard";
import { useWindowDimensions } from "@cbex/utils/hooks";
import dayjs from "dayjs";
import {
  GetExamHistoryWithFiltersDocument,
  CbexPortalExamHistoryWithFilterOptionsRow,
} from "@cbex/data-access";
import { useQuery } from "@apollo/client";

interface ActivityFilterValues {
  dateFrom: any;
  dateTill: any;
}

export const ExamHistoryOverviewComposite = () => {
  const [examDataList, setExamDataList] =
    React.useState<CbexPortalExamHistoryWithFilterOptionsRow[]>();
  const windowSize = useWindowDimensions();
  const [filterValues, setFilterValues] = React.useState<ActivityFilterValues>({
    //@ts-ignore
    dateFrom: dayjs().subtract(3, "month").startOf("day"),
    //@ts-ignore
    dateTill: dayjs().endOf("day"),
  });

  const {
    data: examActivityData,
    loading: examActivityLoading,
    refetch,
  } = useQuery(GetExamHistoryWithFiltersDocument, {
    fetchPolicy: "network-only",
    variables: {
      filter: {
        dateFrom: dayjs(filterValues.dateFrom).startOf("day"),
        dateTill: dayjs(filterValues.dateTill).endOf("day"),
      },
    },
  });

  React.useEffect(() => {
    if (
      !examActivityLoading &&
      examActivityData &&
      examActivityData.ExamHistoryWithFilters &&
      examActivityData.ExamHistoryWithFilters.length > 0
    ) {
      setExamDataList(examActivityData.ExamHistoryWithFilters);
    } else {
      setExamDataList([]);
    }
  }, [examActivityData, examActivityLoading]);

  const handleFilterUpdate = (data: ActivityFilterValues) => {
    setFilterValues(data);
  };

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event === null) {
      setExamDataList(examActivityData.ExamHistoryWithFilters);
    } else {
      setExamDataList(
        examActivityData.ExamHistoryWithFilters.filter(
          (item) =>
            item.moment.location.city
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            item.moment.location.street
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            item.activity.find((act) =>
              act.name.toLowerCase().includes(event.target.value.toLowerCase())
            )
        )
      );
    }
  };

  return (
    <Stack
      spacing={2}
      padding={4}
      maxWidth={1200}
      width={"100%"}
      id="ExamHistoryOverviewComposite"
    >
      <ActivityFilterComponent
        onNewPressed={() => undefined}
        isActivityOverview={false}
        onSearchChanged={handleSearchTextChange}
        inputValues={filterValues}
        isNotCandidateHistory={true}
        onDataChanged={handleFilterUpdate}
      />
      <Stack
        height={windowSize.height - 180}
        spacing={2}
        width="100%"
        overflow={"auto"}
      >
        {examDataList &&
          examDataList.length > 0 &&
          examDataList.map(
            (
              item: CbexPortalExamHistoryWithFilterOptionsRow,
              index: number
            ) => (
              <ActivityCard
                refetch={refetch}
                filterVar={filterValues}
                cardWidth={"100%"}
                inputData={item}
                key={index}
                isHistory={true}
              />
            )
          )}
      </Stack>
    </Stack>
  );
};

export default ExamHistoryOverviewComposite;
