import React from "react";
import { Stack, TextField, CircularProgress } from "@mui/material";
import { GetGoogleMapsAutoCompletePredictionsDocument } from "@cbex/data-access";
import { CustomAutoComplete } from "@cbex/ui/input";
import { useTranslations } from "next-intl";
import _ from "lodash";
import { useLazyQuery } from "@apollo/client";

interface GoogleMapsPlacesAutoCompleteProps {
  onValueChanged: any;
  width?: number;
  name: string;
  disabled: boolean;
  onInputValueChanged: any;
}

export const GoogleMapsPlacesAutoComplete = function (
  props: GoogleMapsPlacesAutoCompleteProps
) {
  const { onValueChanged, width, disabled, onInputValueChanged, name } = props;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState({ placeID: "", name: "" });
  const [sessionToken, setSessionToken] = React.useState();
  const [inputValue, setInputValue] = React.useState(name || "");
  const [initialRun, setInitialRun] = React.useState(false);
  const t = useTranslations("labels");
  const [arrayVal, setArrayVal] = React.useState([]);
  const [GetAutoCompletePredictionResults, { data, loading }] = useLazyQuery(
    GetGoogleMapsAutoCompletePredictionsDocument,
    {
      variables: { searchName: name.toLowerCase(), sessionToken: sessionToken },
    }
  );

  const onChange = function (inputVal: any) {
    onValueChanged(inputVal);
    setOpen(false);
    setValue(inputVal);
  };

  const handleExecute = () => {
    GetAutoCompletePredictionResults();
  };
  const throttledEventHandler = React.useMemo(
    () => _.throttle(handleExecute, 1000),
    []
  );

  React.useEffect(() => {
    if (name.length > 3 && name !== "") {
      setInputValue(name);
      throttledEventHandler();
    }
  }, [name]);

  React.useEffect(() => {
    if (data && data.MapsAutoComplete && data.MapsAutoComplete.sessionToken) {
      if (initialRun === false) {
        setInitialRun(true);
        setSessionToken(data.MapsAutoComplete.sessionToken);
      }
    }
    if (
      data &&
      data &&
      data?.MapsAutoComplete &&
      data.MapsAutoComplete.googleSearchResult &&
      data.MapsAutoComplete.googleSearchResult.length > 0
    ) {
      const newArray = data.MapsAutoComplete.googleSearchResult.map((res) => ({
        id: res.PlaceID,
        sessionToken: data.MapsAutoComplete?.sessionToken,
        name: res.Description,
      }));

      //@ts-ignore
      setArrayVal(newArray);
    }
  }, [data]);
  return (
    <Stack id="GoogleMapsPlacesAutoComplete">
      <CustomAutoComplete
        id="asynchronous-GoogleMapsPlacesAutoComplete"
        sx={{ width: width ? width : 300 }}
        disabled={disabled}
        freeSolo
        // @ts-ignore
        onChange={(event: any, newValue: string | null) => onChange(newValue)}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          if (newInputValue.length >= 3) {
            setOpen(true);
          }
          if (newInputValue.length === 0) {
            setArrayVal([]);
            setOpen(false);
          }
          onInputValueChanged(newInputValue);
          setInputValue(newInputValue);
        }}
        // @ts-ignore
        getOptionLabel={(option) => option.name}
        // @ts-ignore
        value={value}
        // @ts-ignore
        options={arrayVal}
        open={open}
        size="medium"
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            size="medium"
            label={t("googleMapsAutoCompleteSearch")}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
};

export default GoogleMapsPlacesAutoComplete;
