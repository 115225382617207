/* eslint-disable react/jsx-no-useless-fragment */
import React, { Fragment } from "react";
import { useTranslations } from "next-intl";
import {
  Stack,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
} from "@mui/material";
import { CustomTypographyComponent } from "@cbex/ui/text";
import {
  CustomTextfieldController,
  CustomTextfieldTableController,
} from "@cbex/form/textfield";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";
import { CustomDatePickerTableController } from "@cbex/form/datepicker";
import { ExamDetailsDefaultValues } from "../utils/ExamDetailsDefaultValues";
import {
  CbexMomentExam,
  CbexPortalCandidatesByMomentIdRow,
  CandidatesByActivityTypeIDsAndDateRow,
} from "@cbex/data-access";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { SingleActivityTypeAutoComplete } from "../composites/Refactor/SingleActivityTypeAutoComplete";
import { useWindowDimensions } from "@cbex/utils/hooks";
import ImportCandidateModal from "../composites/ImportCandidateModal";

interface CandidateDetailsFormNewProps {
  form: UseFormReturn<ExamDetailsDefaultValues>;
  editAllowed: boolean;
}
export const CandidateDetailsFormNew = ({
  form,
  editAllowed,
}: CandidateDetailsFormNewProps) => {
  const { control, getValues, formState, setValue } = form;
  const { height } = useWindowDimensions();
  const errors = React.useMemo(() => formState.errors, [formState]);
  const [openImportModal, setOpenImportModal] = React.useState(false);

  const StyledTableCell = styled(TableCell)({
    paddingTop: 12,
    paddingBottom: 6,
    paddingLeft: 2,
    paddingRight: 2,
    verticalAlign: "top",
    borderBottom: "0",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "candidates",
  });

  const t = useTranslations();

  const handleRowAdded = () => {
    const allCandidates = getValues("candidates");
    let lastCandidate: CbexPortalCandidatesByMomentIdRow = {
      activityTypeID: "",
      candidateID: "",
      lastName: "",
      dateOfBirth: undefined,
      infix: "",
      initials: "",
      emailAddress: "",
    };
    if (allCandidates && allCandidates.length > 0) {
      const element = allCandidates[allCandidates.length - 1];
      lastCandidate = {
        activityTypeID: element.activityTypeID,
        lastName: "",
        candidateID: "",
        dateOfBirth: undefined,
        emailAddress: "",
        infix: "",
        initials: "",
      };
    }

    append(lastCandidate);
  };

  const onDeleteClickHandler = React.useCallback(
    (itemIndex: number) => {
      remove(itemIndex);
    },
    [remove]
  );
  const CustomRow = React.useMemo(
    () => (
      <React.Fragment>
        {fields &&
          fields.length > 0 &&
          fields.map((row: CbexPortalCandidatesByMomentIdRow, index) => (
            <Fragment //@ts-ignore
              key={row.id}
            >
              <TableRow

              // sx={{ flexGrow: 1 }}
              >
                <StyledTableCell align="left">
                  <SingleActivityTypeAutoComplete
                    width={"auto"}
                    sx={{ minWidth: 300 }}
                    control={control}
                    controlDisabled={!editAllowed}
                    //@ts-ignore
                    required
                    name={`candidates.${index}.activityTypeID`}
                    //@ts-ignore
                    defaultValue={row.activityTypeID}
                    //@ts-ignore
                    inputPlanningActivityTypes={
                      getValues("activityTypeIDs").length > 0 &&
                      getValues("activityTypeIDs")
                    }
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <CustomTextfieldTableController
                    sx={{ width: "100%" }}
                    name={`candidates.${index}.initials`}
                    required
                    control={control}
                    controlDisabled={!editAllowed}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <CustomTextfieldController
                    sx={{ width: "100%" }}
                    controlDisabled={!editAllowed}
                    name={`candidates[${index}].infix`}
                    control={control}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <CustomTextfieldTableController
                    sx={{ minWidth: 180, width: "100%" }}
                    controlDisabled={!editAllowed}
                    name={`candidates.${index}.lastName`}
                    required
                    control={control}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <CustomDatePickerTableController
                    width={"100%"}
                    sx={{ minWidth: 150, width: "100%" }}
                    controlDisabled={!editAllowed}
                    label={""}
                    control={control}
                    required
                    name={`candidates.${index}.dateOfBirth`}
                    views={["year", "month", "day"]}
                    incomingError={t("labels.required")}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <CustomTextfieldController
                    sx={{ minWidth: 300, width: "100%" }}
                    controlDisabled={!editAllowed}
                    name={`candidates.${index}.emailAddress`}
                    control={control}
                  />
                </StyledTableCell>

                <StyledTableCell align="left">
                  <IconButton
                    disabled={!editAllowed}
                    onClick={() => onDeleteClickHandler(index)}
                  >
                    <DeleteIcon
                      color={editAllowed ? "primary" : "disabled"}
                      titleAccess={t("labels.removeRow")}
                    />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    paddingTop: 0,
                    paddingRight: 0.5,
                    paddingLeft: 0.5,
                    paddingBottom: 0.5,
                  }}
                  align="left"
                  colSpan={6}
                >
                  <CustomTextfieldController
                    sx={{ minWidth: 220, width: "100%" }}
                    id="outlined-adornment-note"
                    label={t("labels.note")}
                    controlDisabled={!editAllowed}
                    multiline
                    // rows={3}
                    control={control}
                    name={`candidates.${index}.notes`}
                  />
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
      </React.Fragment>
    ),
    [StyledTableCell, control, fields, getValues, onDeleteClickHandler, t]
  );

  const onImportModalDataAdded = (
    inputCandidates: CandidatesByActivityTypeIDsAndDateRow[]
  ) => {
    setOpenImportModal(false);

    const allCandidates = getValues("candidates");
    const newCanidates = inputCandidates.map((candidateItem) => ({
      activityTypeID: candidateItem.activityTypeID,
      candidateID: "",
      lastName: candidateItem.lastName,
      dateOfBirth: candidateItem.dateOfBirth || undefined,
      infix: candidateItem.infix || "",
      initials: candidateItem.initials || "",
      emailAddress: candidateItem.emailAddress || "",
    }));

    setValue("candidates", allCandidates.concat(newCanidates));
  };
  return (
    <Stack justifyContent={"space-around"} height={height - 230}>
      <Stack direction={"row"} padding={2} justifyContent={"space-between"}>
        <CustomTypographyComponent variant="h6">
          {t("common.examCandidates")}
        </CustomTypographyComponent>
        <Stack spacing={3} direction={"row"}>
          <Button
            disabled={!editAllowed}
            onClick={() => setOpenImportModal(true)}
            variant="contained"
          >
            {t("common.importCandidates")}
          </Button>
          <Button
            disabled={!editAllowed}
            onClick={() => {
              handleRowAdded();
            }}
            variant="contained"
          >
            {t("labels.addRow")}
          </Button>
        </Stack>
      </Stack>
      <Paper>
        <Stack paddingBottom={4} paddingLeft={2}>
          <TableContainer sx={{ height: height - 320 }}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">
                    {t("labels.activity")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("labels.initials")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("labels.infix")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("labels.lastName")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("labels.dob")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {t("labels.emailAddress")}
                  </StyledTableCell>

                  <StyledTableCell width={20} align="left"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>{CustomRow}</TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>

      {openImportModal && (
        <ImportCandidateModal
          modalOpen={openImportModal}
          handleClose={() => setOpenImportModal(false)}
          handleCloseWithData={onImportModalDataAdded}
          activityTypeIDs={getValues("activityTypeIDs")}
        />
      )}
    </Stack>
  );
};

export default CandidateDetailsFormNew;
