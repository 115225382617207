/* eslint-disable react/jsx-no-useless-fragment */
import { CandidatesByActivityTypeIDsAndDateRow } from "@cbex/data-access";
import { useWindowDimensions } from "@cbex/utils/hooks";
import { Button, Paper, Stack } from "@mui/material";
import { useTranslations } from "next-intl";
import { DataGridComponent } from "@cbex/ui/table";
import React from "react";

interface ImportCandidatesDetailsProps {
  data: CandidatesByActivityTypeIDsAndDateRow[];
  onImportClick: (candidates: CandidatesByActivityTypeIDsAndDateRow[]) => void;
}

export const ImportCandidatesDetails = ({
  data,
  onImportClick,
}: ImportCandidatesDetailsProps) => {
  const t = useTranslations();
  const [candidateData, setCandidateData] = React.useState([]);
  const [selectedRowIDs, setSelectedRowIDs] = React.useState([]);
  const { height } = useWindowDimensions();
  React.useEffect(() => {
    if (data && data.length > 0) {
      setCandidateData(data);
    }
  }, [data]);

  const candidateColumnData: any[] = [
    {
      field: "startDate",
      headerName: t("labels.startDate"),
      type: "date",
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: "activityName",
      headerName: t("labels.activity"),
      width: 200,
      valueGetter: ({ value }) => value,
    },
    {
      field: "initials",
      headerName: t("labels.initials"),

      valueGetter: ({ value }) => value,
    },
    {
      field: "infix",
      headerName: t("labels.infix"),
      width: 150,
      valueGetter: ({ value }) => value,
    },
    {
      field: "lastName",
      headerName: t("labels.lastName"),
      width: 150,

      sortable: true,
      valueGetter: ({ value }) => value,
      editable: false,
    },
    {
      field: "dateOfBirth",
      width: 200,
      headerName: t("labels.dob"),
      type: "date",
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: "emailAddress",
      headerName: t("labels.email"),
      valueGetter: ({ value }) => value,
    },
  ];

  const handleCheckBoxSelection = (rows: any[]) => {
    setSelectedRowIDs(rows);
  };

  const handleImportClick = () => {
    const getCandidates = candidateData.filter((candidateItem) =>
      selectedRowIDs.includes(candidateItem.candidateID)
    );
    onImportClick(getCandidates);
  };

  return (
    <Stack id="ImportCandidatesDetails">
      <Stack p={1} spacing={2} alignItems={"flex-end"}>
        {selectedRowIDs && selectedRowIDs.length > 0 && (
          <Button
            fullWidth={false}
            sx={{ width: 250 }}
            onClick={handleImportClick}
            variant="contained"
          >
            {t("labels.addCandidates")}
          </Button>
        )}
      </Stack>

      <Paper>
        {candidateData && (
          // @ts-ignore
          <DataGridComponent
            getCustomID={(row) => row.candidateID}
            enableCheckbox={true}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  candidateID: false,
                },
              },
            }}
            columns={candidateColumnData}
            searchenabled={true}
            handleSelectedRows={handleCheckBoxSelection}
            density="compact"
            rowHeight={60}
            rowData={candidateData}
            height={height - 300}
            width={"100%"}
          />
        )}
      </Paper>
    </Stack>
  );
};

export default ImportCandidatesDetails;
