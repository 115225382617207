import React from "react";
import { Chip, Stack, SxProps, Theme } from "@mui/material";
import { CustomTextField } from "@cbex/ui/input";
import { PlanningActivityType } from "@cbex/data-access";
import { useTranslations } from "next-intl";
import { CustomAutoCompleteController } from "@cbex/form/autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { CustomTypographyComponent } from "@cbex/ui/text";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface ActivityTypeAutoCompleteProps {
  inputPlanningActivityTypes: PlanningActivityType[];
  defaultValue?: any;
  width?: number | string;
  name: string;
  controlDisabled?: boolean;
  control?: any;
  sx?: SxProps<Theme>;
}

export const SingleActivityTypeAutoComplete = ({
  inputPlanningActivityTypes,
  control,
  defaultValue,
  width,
  name,
  controlDisabled,
  sx,
}: ActivityTypeAutoCompleteProps) => {
  const t = useTranslations("labels");
  const [value, setValue] = React.useState(null);

  const [options, setOptions] = React.useState<PlanningActivityType[]>([]);

  React.useEffect(() => {
    if (inputPlanningActivityTypes && inputPlanningActivityTypes.length > 0) {
      setOptions(inputPlanningActivityTypes);
    } else {
      setOptions([]);
    }
  }, [inputPlanningActivityTypes]);

  React.useEffect(() => {
    if (
      defaultValue &&
      inputPlanningActivityTypes &&
      inputPlanningActivityTypes.length > 0
    ) {
      const foundAct = inputPlanningActivityTypes.find(
        (act: PlanningActivityType) => act.activityTypeID === defaultValue
      );
      setValue(foundAct);
    }
  }, [defaultValue, inputPlanningActivityTypes]);

  const handleActivityChange = (inputValue: any) => {
    setValue(inputValue);
  };

  return (
    <Stack id="ActivityTypeAutoComplete">
      {inputPlanningActivityTypes && inputPlanningActivityTypes.length > 0 && (
        <CustomAutoCompleteController
          id="async-activitiesAutoComplete"
          sx={[
            { width: width ? width : 500 },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          control={control}
          customID="activityTypeID"
          disabled={controlDisabled}
          fullObject={true}
          disableClearable={true}
          onCustomChange={(val) => handleActivityChange(val)}
          name={name}
          //@ts-ignore
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, val) =>
            option.activityTypeID === val.activityTypeID
          }
          // @ts-ignore
          renderOption={(props, option) => (
            <li {...props} key={option.activityTypeID}>
              <Stack width={"100%"}>
                <CustomTypographyComponent>
                  {option.name}
                </CustomTypographyComponent>
              </Stack>
            </li>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option.activityTypeID}
                label={option.name}
              />
            ))
          }
          value={value || null}
          options={options}
          defaultValue={value || null}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              controldisabled={false}
              // error={!!error}
              // helperText={error ? error.message : null}
              variant="filled"
            />
          )}
        />
      )}
    </Stack>
  );
};

export default SingleActivityTypeAutoComplete;
