import React from "react";
import { FormControl, Paper, Stack } from "@mui/material";
import { CustomModal } from "@cbex/ui/modal";
import { useTranslations } from "next-intl";
import {
  ExamAddressesAutoComplete,
  GetActivityTypeList,
  GetPlanningResourcesList,
} from "../index";
import { CbexMomentExam, ExamByMomentIdDocument } from "@cbex/data-access";
import { CustomTextField } from "@cbex/ui/input";
import dayjs from "dayjs";
import { useQuery } from "@apollo/client";

interface ExamOverviewModalProps {
  onModalOpen: boolean;
  onModalClose: () => void;
  momentID: string;
  refetch: any;
}

export const EditExamOverviewModal = (props: ExamOverviewModalProps) => {
  const { onModalClose, onModalOpen, momentID, refetch } = props;
  const t = useTranslations();
  const [currentExam, setCurrentExam] = React.useState<CbexMomentExam>();
  const { data: examData, loading: examLoading } = useQuery(
    ExamByMomentIdDocument,
    {
      variables: {
        momentID: momentID,
      },
      fetchPolicy: "network-only",
    }
  );

  React.useEffect(() => {
    if (!examLoading && examData && examData.ExamByMomentID) {
      setCurrentExam(examData.ExamByMomentID);
    } else {
      setCurrentExam(undefined);
    }
  }, [examData, examLoading]);

  const handleModalClose = () => {
    if (onModalClose) {
      refetch();
      onModalClose();
    }
  };

  return (
    <Stack id="EditExamOverviewModal">
      <CustomModal
        modalTitle={t("viewExam")}
        openModal={onModalOpen}
        minWidth={900}
        closeHandler={handleModalClose}
      >
        <Paper>
          {/* {!examLoading && currentExam && (
            <ExamOverviewStepper
              onExamData={currentExam}
              isReadOnly={true}
              onStepperCompleted={handleModalClose}
            />
          )} */}

          {!examLoading && currentExam && (
            <Stack id="ExamDetailsForm" spacing={2} padding={1}>
              <Stack spacing={2} padding={1} direction={"column"}>
                <FormControl variant="standard" sx={{ width: "300px" }}>
                  <CustomTextField
                    label={
                      //@ts-ignore
                      t("startDate")
                    }
                    controldisabled={true}
                    value={
                      currentExam
                        ? dayjs(currentExam.moment.startDate).format(
                            "DD/MM/YYYY HH:mm"
                          )
                        : null
                    }
                    //onChange={handleDateChanged}
                  />
                </FormControl>
              </Stack>
              <Stack spacing={2} padding={1} direction="row">
                <FormControl variant="filled" sx={{ width: "300px" }}>
                  <ExamAddressesAutoComplete
                    inputParam={currentExam.moment.locationIds[0]}
                    controlDisabled={true}
                    addresType="EXAM"
                    onValueChanged={(val) => console.log(val)}
                  />
                </FormControl>
              </Stack>
              <Stack spacing={2} padding={1} direction={"column"}>
                <FormControl
                  sx={{ width: "400px", height: 60 }}
                  variant="filled"
                >
                  <GetActivityTypeList
                    inputParam={currentExam.activities[0].activityTypeID}
                    controlDisabled={true}
                    onValueChanged={(val) => console.log(val)}
                  />
                </FormControl>
                <FormControl sx={{ width: 400 }} variant="filled">
                  <GetPlanningResourcesList
                    inputParam={
                      currentExam &&
                      currentExam.moment.resources &&
                      currentExam.moment.resources.length > 0
                        ? currentExam.moment.resources[0]
                        : undefined
                    }
                    activityTypeID={currentExam.activities[0].activityTypeID}
                    label="examiner"
                    controlDisabled={true}
                    onValueChanged={(val) => console.log(val)}
                  />
                </FormControl>
              </Stack>
            </Stack>
          )}
        </Paper>
      </CustomModal>
    </Stack>
  );
};

export default EditExamOverviewModal;
