import { Stack } from "@mui/system";
import React from "react";
import { ActivityFilterComponent } from "./ActivityFilterComponent";
import { useWindowDimensions } from "@cbex/utils/hooks";
import * as dayjs from "dayjs";
import {
  GetCandidatesHistoryWithFiltersDocument,
  CbexPortalCandidatesWithFiltersRow,
  GetCertificateDocumentDataDocument,
} from "@cbex/data-access";
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { CustomTypographyComponent } from "@cbex/ui/text";
import { useTranslations } from "next-intl";
import { DataGridComponent } from "@cbex/ui/table";
import DownloadIcon from "@mui/icons-material/Download";
import { Paper } from "@mui/material";
import { downLoadCommunisPDF } from "../utils";
import { useLazyQuery, useQuery } from "@apollo/client";

interface ActivityFilterValues {
  dateFrom: any;
  dateTill: any;
}

export const CandidateHistoryOverviewComposite = () => {
  const [currentCandidate, setCurrentCandidate] =
    React.useState<CbexPortalCandidatesWithFiltersRow>();
  const actionStates = ["PASSED", "FAILED"];
  const [candidateDataList, setCandidateDataList] =
    React.useState<CbexPortalCandidatesWithFiltersRow[]>();
  const t = useTranslations();
  const windowSize = useWindowDimensions();

  const getDisabledState = (row) => {
    if (
      (row.activity.properties === undefined || row.activity.properties) &&
      row.activity.properties.template === undefined
    ) {
      return true;
    } else if (!actionStates.includes(row.result)) {
      return true;
    } else {
      return false;
    }
  };

  const candidateColumnData: any[] = [
    {
      field: "id",
      headerName: t("labels.id"),
    },
    {
      field: "initials",
      headerName: t("labels.initials"),
      flex: 0.1,
      editable: false,
      sortable: false,
    },
    {
      field: "infix",
      headerName: t("labels.infix"),
      flex: 0.1,
      sortable: true,
      editable: false,
    },
    {
      field: "lastName",
      headerName: t("labels.lastName"),
      flex: 0.3,
      sortable: true,
      editable: false,
    },
    {
      field: "startDate",
      headerName: t("labels.activityDate"),
      flex: 0.2,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <div style={{ width: "100%" }}>
          <CustomTypographyComponent>
            {
              //@ts-ignore
              params.value ? dayjs(params.value).format("DD-MM-YYYY") : ""
            }
          </CustomTypographyComponent>
        </div>
      ),
      //@ts-ignore
      valueFormatter: ({ value }) => dayjs(value).format("DD-MM-YYYY"),
    },
    {
      field: "activity",
      headerName: t("labels.activity"),
      flex: 0.4,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <div style={{ width: "100%" }}>
          <CustomTypographyComponent>
            {
              //@ts-ignore
              params.value ? params.row.activity.name : ""
            }
          </CustomTypographyComponent>
        </div>
      ),
      valueGetter: ({ value }) => (value.name ? value.name : ""),
    },

    {
      field: "dateOfBirth",
      headerName: t("labels.dob"),
      flex: 0.2,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <div style={{ width: "100%" }}>
          <CustomTypographyComponent>
            {
              //@ts-ignore
              params.value ? dayjs(params.value).format("DD-MM-YYYY") : ""
            }
          </CustomTypographyComponent>
        </div>
      ),
      //@ts-ignore
      valueFormatter: ({ value }) => dayjs(value).format("DD-MM-YYYY"),
    },
    {
      field: "emailAddress",
      headerName: t("labels.email"),
      flex: 0.2,
      sortable: false,
      editable: false,
    },
    {
      field: "notes",
      headerName: t("labels.note"),
      flex: 0.2,
      sortable: false,
      editable: false,
    },
    {
      field: "result",
      headerName: t("labels.result"),
      flex: 0.2,
      sortable: false,
      editable: false,
      valueGetter: ({ value }) => (value ? t(`value.${value}`) : ""),
    },
    {
      field: "score",
      headerName: t("labels.score"),
      flex: 0.2,
      sortable: false,
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("labels.actions"),
      getActions: (params: GridRowParams) => [
        //@ts-ignore
        <GridActionsCellItem
          key={0}
          disabled={getDisabledState(params.row)}
          icon={
            <DownloadIcon
              titleAccess={t("labels.downloadCertificate")}
              //@ts-ignore
              color={!getDisabledState(params.row) ? "primary" : "disabled"}
            />
          }
          label={t("labels.downloadCertificate")}
          onClick={() => handleDownloadClick(params)}
        />,
      ],
    },
  ];

  const [filterValues, setFilterValues] = React.useState<ActivityFilterValues>({
    //@ts-ignore
    dateFrom: dayjs().subtract(3, "month"),
    //@ts-ignore
    dateTill: dayjs(),
  });

  const { data: candidateData, loading: candidateLoading } = useQuery(
    GetCandidatesHistoryWithFiltersDocument,
    {
      fetchPolicy: "network-only",
      variables: {
        filter: {
          dateFrom: filterValues.dateFrom,
          dateTill: filterValues.dateTill,
        },
      },
    }
  );

  React.useEffect(() => {
    if (
      !candidateLoading &&
      candidateData &&
      candidateData.CandidatesHistoryWithFilters &&
      candidateData.CandidatesHistoryWithFilters.length > 0
    ) {
      setCandidateDataList(candidateData.CandidatesHistoryWithFilters);
    } else {
      setCandidateDataList([]);
    }
  }, [candidateData, candidateLoading]);

  const handleFilterUpdate = (data: ActivityFilterValues) => {
    setFilterValues(data);
  };

  const [
    getCertificateDocumentData,
    { data: CertificateDocumentData, loading: CertificateDocumentDataLoading },
  ] = useLazyQuery(GetCertificateDocumentDataDocument, {
    variables: {
      ids: [],
    },
    fetchPolicy: "network-only",
    //@ts-ignore
  });

  const handleDownloadClick = (params: GridRowParams) => {
    setCurrentCandidate(params.row);
    getCertificateDocumentData({
      variables: {
        ids: [params.row.candidateID],
      },
    });
  };

  React.useEffect(() => {
    if (
      CertificateDocumentData &&
      CertificateDocumentData.CertificateDocumentData &&
      CertificateDocumentData.CertificateDocumentData.length > 0
    ) {
      downLoadCommunisPDF({
        downloadName: "cbexCertificate-download",
        pdfObject: {
          ...CertificateDocumentData.CertificateDocumentData[0],
          score: currentCandidate.score,
        },
        templateType:
          currentCandidate.result === "PASSED"
            ? CertificateDocumentData.CertificateDocumentData[0].activity
                .properties.template
            : "examResultFailPDF",
      });
    }
  }, [CertificateDocumentData]);

  return (
    <Stack
      spacing={2}
      padding={4}
      width="100%"
      id="CandidateHistoryOverviewComposite"
    >
      <ActivityFilterComponent
        onNewPressed={() => undefined}
        isActivityOverview={false}
        inputValues={filterValues}
        onDataChanged={handleFilterUpdate}
      />
      <Stack spacing={2} width="100%">
        {/* <Paper> */}
        {!candidateLoading && candidateDataList && (
          //@ts-ignore
          <DataGridComponent
            enableCheckbox={false}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  emailAddress: false,
                  id: false,
                  notes: false,
                },
              },
            }}
            columns={candidateColumnData}
            searchenabled={true}
            exportEnabled
            //density="compact"
            rowHeight={60}
            rowData={candidateDataList}
            height={windowSize.height - 160}
            width={"100%"}
          />
        )}
        {/* </Paper> */}
      </Stack>
    </Stack>
  );
};

export default CandidateHistoryOverviewComposite;
