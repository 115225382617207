export const CountryListArray = [
  {
    name: "Belgium",
    code: "BE",
  },
  {
    name: "Germany",
    code: "DE",
  },
  {
    name: "Lithuania",
    code: "LT",
  },
  {
    name: "Netherlands",
    code: "NL",
  },
  {
    name: "Poland",
    code: "PL",
  },
];
