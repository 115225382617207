import { FormControl, FormHelperText, Stack } from "@mui/material";
import { useTranslations } from "next-intl";
import React from "react";
import { GetActivityTypeList, GetPlanningResourcesList } from "../index";
import { SnackBar } from "@cbex/ui/snack-bar";
import { useTheme } from "@cbex/utils/theme";

interface ActivityFormFormProps {
  onActivityDetails: (val: ActivityFormState) => void;
  data?: ActivityFormState;
}

export interface ActivityFormState {
  activityID: string;
  resources: string[];
}

interface ActivityFormFormError {
  activityID: string;
  resources: string;
}

export const ActivityForm = React.forwardRef(
  (props: ActivityFormFormProps, ref) => {
    const t = useTranslations();
    const { onActivityDetails, data } = props;

    const theme = useTheme();
    const [values, setValues] = React.useState<ActivityFormState>({
      activityID: "",
      resources: [],
    });
    const [formIsSubmitted, setFormIsSubmitted] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("");
    const [formErrors, setFormErrors] = React.useState<ActivityFormFormError>({
      activityID: t("missingValues", { ns: "feedback" }),
      resources: t("missingValues", { ns: "feedback" }),
    });

    React.useEffect(() => {
      if (data) {
        setValues({
          activityID: data.activityID,
          resources: data.resources || [],
        });
        checkRequiredValues(data);
        setFormIsSubmitted(true);
      }
    }, [data]);

    const handleChange =
      (prop: keyof ActivityFormState) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
      };
    const handleActivityTypeChange = (val: any) => {
      if (val) {
        setValues({
          ...values,
          activityID: val.id,
          resources: [],
        });
        setFormErrors({ ...formErrors, activityID: "" });
      } else {
        setFormErrors({
          ...formErrors,
          activityID: t("missingValues", { ns: "feedback" }),
        });
      }
    };

    const handleResourceSelected = (val: any) => {
      if (val) {
        setValues({ ...values, resources: [val] });
        setFormErrors({ ...formErrors, resources: "" });
      } else {
        setFormErrors({
          ...formErrors,
          resources: t("missingValues", { ns: "feedback" }),
        });
      }
    };

    const checkRequiredValues = (val) => {
      if (val.activityID === "" || val.locationID === "") {
        setFormErrors({
          activityID:
            val.activityID !== "" ? "" : t("missingValues", { ns: "feedback" }),
          resources:
            val.resources !== "" ? "" : t("missingValues", { ns: "feedback" }),
        });
      } else {
        setFormErrors({
          activityID: "",
          resources: "",
        });
      }
    };

    React.useImperativeHandle(ref, () => ({
      onSubmit() {
        setFormIsSubmitted(true);
        if (Object.values(formErrors).some((item) => item.length > 0)) {
          setSnackBar(true);
          setSeverity("error");
          setSnackBarMessage(t("missingValues", { ns: "feedback" }));
        } else {
          setFormErrors({ activityID: "", resources: "" });
          onActivityDetails(values);
        }
      },
    }));

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      setSnackBar(false);
    };
    return (
      <Stack id="ActivityForm" direction="row" spacing={2} padding={1}>
        <Stack spacing={2} padding={1} direction={"column"}>
          <FormControl sx={{ width: "400px", height: 60 }} variant="filled">
            <GetActivityTypeList
              inputParam={values.activityID}
              controlDisabled={false}
              onValueChanged={handleActivityTypeChange}
            />
            {formIsSubmitted && (
              <FormHelperText
                sx={{
                  color: formErrors.activityID ? theme.appColors.error : "",
                }}
                id="filled-name-helper-text"
              >
                {formErrors.activityID ? formErrors.activityID : ""}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ width: 400 }} variant="filled">
            <GetPlanningResourcesList
              inputParam={
                values && values.resources && values.resources.length > 0
                  ? values.resources[0]
                  : undefined
              }
              activityTypeID={values.activityID}
              label="examiner"
              required
              controlDisabled={values.activityID === ""}
              onValueChanged={handleResourceSelected}
            />
            {formIsSubmitted && (
              <FormHelperText
                sx={{
                  color: formErrors.resources ? theme.appColors.error : "",
                }}
                id="filled-name-helper-text"
              >
                {formErrors.resources ? formErrors.resources : ""}
              </FormHelperText>
            )}
          </FormControl>
        </Stack>
        {snackBar && (
          <SnackBar
            openState={snackBar}
            //@ts-ignore
            severity={severity}
            message={snackBarMessage}
            //@ts-ignore
            onClose={handleClose}
          ></SnackBar>
        )}
      </Stack>
    );
  }
);

ActivityForm.displayName = "ActivityForm";

export default ActivityForm;
