import React from "react";
import { Controller } from "react-hook-form";
import {
  CustomDatePickerComponent,
  CustomDatePickerComponentProps,
} from "@cbex/ui/input";

export type CustomDatePickerControllerProps = CustomDatePickerComponentProps & {
  name: string;
  control: any;
  label: string;
  setValue?: any;
  controlDisabled?: boolean;
  textFieldFullWidth?: boolean;
  incomingError?: string;
  width?: number | string;
};

export const CustomDatePickerTableController = ({
  name,
  control,
  textFieldFullWidth,
  controlDisabled,
  incomingError,
  label,
  width,
  ...rest
}: CustomDatePickerControllerProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <CustomDatePickerComponent
        value={value}
        controldisabled={controlDisabled}
        textFieldFullWidth={textFieldFullWidth}
        label={error ? incomingError : null}
        onChange={onChange}
        error={!!error}
        width={width}
        slotProps={{
          textField: {
            error: !!error,
            variant: "filled",
            size: "small",
          },
        }}
        {...rest}
      />
    )}
  />
);

export const CustomDatePickerController = ({
  name,
  control,
  textFieldFullWidth,
  controlDisabled,
  incomingError,
  label,
  width,
  ...rest
}: CustomDatePickerControllerProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <CustomDatePickerComponent
        value={value}
        controldisabled={controlDisabled}
        textFieldFullWidth={textFieldFullWidth}
        label={label}
        onChange={onChange}
        error={!!error}
        width={width}
        slotProps={{
          textField: {
            error: !!error,
            variant: "filled",
            size: "small",
            helperText: error ? incomingError : null,
          },
        }}
        {...rest}
      />
    )}
  />
);
