import React from "react";
import { Controller } from "react-hook-form";
import {
  CustomDateTimePickerComponent,
  CustomDateTimePickerComponentProps,
} from "@cbex/ui/input";

export type CustomDateTimePickerControllerProps =
  CustomDateTimePickerComponentProps & {
    name: string;
    control: any;
    label: string;
    setValue?: any;
    controlDisabled?: boolean;
    width?: number | string;
  };

export const CustomDateTimePickerController = ({
  name,
  control,
  controlDisabled,
  label,
  width,
  ...rest
}: CustomDateTimePickerControllerProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <CustomDateTimePickerComponent
        value={value}
        error={!!error}
        controldisabled={controlDisabled}
        label={label}
        onChange={onChange}
        width={width}
        slotProps={{
          textField: {
            error: !!error,
            variant: "filled",
            size: "small",
          },
        }}
        {...rest}
      />
    )}
  />
);
